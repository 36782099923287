import React, { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Breadcrumbs, H3 } from "../../AbstractElements";
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Row, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import {
    Address, Company, Email, MobileNo, AfterNo, BeforeNo, Comma, Dash, DateFive, DateFormate, DateFour, DateOne, DateSix,
    DateThree, DateTwo, Dot, FinancialYearStart, Next, PerPosition, Separator, Slash, Space, TimeZone, QuantityName,
    DefaultCurrency, DefaultLang, Localisation, Invoice, Defaults, AddNewCompany, Invoiceprefix, Invoicesiffix, Submit,
    Decimal, DefaultDueDays, CompanyName, GSTTax,
} from "../../Constant/index";
import PhoneInput from "react-phone-input-2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getClientUrlDataToken, postClientUrlWithToken } from "../../Utils/restUtils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CompanyAdd, getCurrency } from "../../CommonUrl";
import { toast } from "react-toastify";
import Typeahead from "../../CommonElements/TypeAhead/index";
import asiaTimezones from "../Common/Timezone/Timezone";
import { duedateOptions } from "./Duedate";
import DatePicker from 'react-datepicker';
import moment from "moment";
import Transparentspinner from "../Common/Loader/Transparentspinner";
import HandleApiError from "../Common/ErrorImport/ErrorImport";

const validationSchema = yup.object().shape({
    email: yup
        .string()
        .email("Invalid email format")
        .required("Email is required"),
    name: yup.string().required("Name is required"),
    phone: yup.string().required("Phone number is required"),
});

const Companyadd = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [logo, setLogo] = useState('');
    const [icon, setIcon] = useState('');
    const [IconWithTab, setIconWithTab] = useState('1');
    const form = new FormData();
    const authState = useSelector((state) => state.auth);
    const [financialDate, setFinancialDate] = useState(null);
    const [currencyOptions, setCurrencyOptions] = useState([]);

    const dateformatOptions = [
        { value: "d M Y", label: DateOne },
        { value: "M d Y", label: DateTwo },
        { value: "Y M d", label: DateThree },
        { value: "d m Y", label: DateFour },
        { value: "m d Y", label: DateFive },
        { value: "Y m d", label: DateSix },
    ];

    const specialCharacterOptions = [
        { value: "-", label: Dash },
        { value: "/", label: Slash },
        { value: ".", label: Dot },
        { value: ",", label: Comma },
        { value: " ", label: Space },
    ];

    const positionOptions = [
        { value: "after", label: AfterNo },
        { value: "before", label: BeforeNo },
    ];

    const quantityOptions = [{ value: "quantitiy", label: "Quantitiy" }];
    const decimalOptions = [
        { value: "2", label: "0.00" },
        { value: "3", label: "0.000" },
        { value: "4", label: "0.0000" },
    ];

    const languageOptions = [{ value: "english", label: "ENGLISH (GB)" }];

    useEffect(() => {
        currencyApi();
        const inputElement = document.querySelectorAll('input[type="number"]');
        const preventScrollEvent = (event) => {
            event.preventDefault();
        };
        inputElement.forEach((inputElement) => {
            inputElement.addEventListener('wheel', preventScrollEvent);

        });
    }, []);

    const { register, handleSubmit, setValue, control, formState: { errors }, trigger } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const currencyApi = async () => {
        const data = await getClientUrlDataToken(authState.apiUrl, getCurrency);
        if (data.status === true) {
            const transformedData = data.data.map((item) => ({
                label: item.name,
                value: item.code
            }));
            setCurrencyOptions(transformedData);
        } else {
            HandleApiError(response.status)
        }
    };

    const handleChangeDate = date => {
        setFinancialDate(date);
    };

    const handleLogoChange = (event) => {
        const selectedFile = event.target.files[0];
        setLogo(selectedFile);
    };

    const handleIconChange = (event) => {
        const selectedFile = event.target.files[0];
        setIcon(selectedFile);
    };

    const nextTab = async () => {
        const isValid = await trigger()
        if (isValid) {
            const nextTab = String(Number(IconWithTab) + 1);
            if (nextTab <= '4') {
                setIconWithTab(nextTab)
            }
        }
    }

    const onSubmit = async (data) => {
        const countryCode = data.countryCode;
        const mobile = data.phone;
        if (mobile && countryCode) {
            setLoading(true)
            const phoneno = mobile.slice(countryCode.length)
            form.append("name", data.name);
            form.append("email", data.email);
            form.append("phone", phoneno);
            form.append("country_code", countryCode);
            form.append("address", data.address || "");
            form.append("tax_in", data.taxin);
            if (data.logo) {
                form.append("logo", data.logo[0]);
            }
            if (data.financialDate) {
                const formattedFinancialDate = moment(data.financialDate).format("DD-MM-YYYY");
                form.append("financial_year", formattedFinancialDate);
            }
            form.append("financial_year", data?.financial || "");
            form.append("time_zone", data?.timezone?.value || "");
            form.append("date_format", data.dateFormate?.value || "");
            form.append("separator", data.separator?.value || "");
            form.append("position", data.position?.value || "");
            form.append("decimal", data.decimal?.value || "");
            form.append("item_name", data.Itemname?.value || "");
            form.append("price_name", data.pricename?.value || "");
            form.append("quantity_name", data.quantityname?.value || "");
            form.append("invoice_prefix", data.invprefix || "");
            form.append("invoice_suffix", data.invsuffix || "");
            form.append("due_date", data.duedate?.value || "");
            if (data.icon) {
                form.append("icon", data.icon[0]);
            }

            form.append("currency", data.currency?.value || "");
            form.append("language", data.language?.value || "");
            const postResponce = await postClientUrlWithToken(
                authState.apiUrl,
                CompanyAdd,
                form,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (postResponce.status === 200) {
                if (postResponce.data.status === true) {
                    toast.success(postResponce.data.message);
                    navigate(`/subscriptionAddition`, { state: { id: postResponce.data.data.id } });
                } else {
                    if (Array.isArray(postResponce.data.message)) {
                        postResponce.data.map((e) => { e.message })
                    } else {
                        toast.error(postResponce.data.message);
                    }
                    setLoading(false)
                }
            } else if (postResponce.status >= 400 && postResponce.status <= 405) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
            } else if (postResponce.status >= 500 && postResponce.status <= 505) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
            } else {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
            }
        }

    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                <Breadcrumbs mainTitle={AddNewCompany} parent="Company" title={AddNewCompany} />
                <Card className="tab-company-edit">
                    <Nav className="nav-primary" tabs>
                        <NavItem >
                            <NavLink className={IconWithTab === '1' ? 'active' : ''} onClick={() => setIconWithTab('1')}><span className="fs-6 fw-bold cursor ">{Company}</span></NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={IconWithTab === '2' ? 'active' : ''} onClick={() => setIconWithTab('2')}><span className="fs-6 fw-bold cursor">{Localisation}</span></NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={IconWithTab === '3' ? 'active' : ''} onClick={() => setIconWithTab('3')}><span className="fs-6 fw-bold cursor">{Invoice}</span></NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={IconWithTab === '4' ? 'active' : ''} onClick={() => setIconWithTab('4')}><span className="fs-6 fw-bold cursor">{Defaults}</span></NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={IconWithTab}>

                        <TabPane className="fade show" tabId="1">
                            <CardHeader className="pb-0">
                                <H3>{Company}</H3>
                            </CardHeader>
                            <CardBody>
                                <Row className="g-3">
                                    <Col sm="12" md="6">
                                        <Label>{CompanyName} <span className="requireStar">*</span></Label>
                                        <input className="form-control" type="text" {...register("name")} />
                                        <span className="text-danger pt-2">{errors.name && errors.name.message}</span>
                                    </Col>
                                    <Col md="6">
                                        <Label htmlFor="email">{Email} <span className="requireStar">*</span></Label>
                                        <input className="form-control" type="text" name="email" {...register("email")} />
                                        <span className="text-danger">{errors.email && errors.email.message}</span>
                                    </Col>
                                </Row>
                                <Row className="g-3 pt-3">
                                    <Col md="6">
                                        <Label htmlFor="gst">{GSTTax}</Label>
                                        <input className="form-control" type="text" name="taxin" {...register("taxin")} />
                                        <span className="text-danger">{errors.taxin && errors.taxin.message}</span>
                                    </Col>
                                    <Col md="6">
                                        <Label className="form-label" >{MobileNo} <span className="requireStar">*</span></Label>
                                        <Controller
                                            name="phone"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <PhoneInput
                                                    {...field}
                                                    country={"in"}
                                                    enableSearch={true}
                                                    onChange={(value, country) => {
                                                        setValue("countryCode", country.dialCode);
                                                        field.onChange(value);
                                                    }}
                                                    inputStyle={{
                                                        width: "100%",
                                                        height: "46px",
                                                        padding: "10px",
                                                        paddingLeft: "50px",
                                                        fontSize: "16px",
                                                        borderRadius: "8px",
                                                        borderColor: "rgba(83, 70, 134, 0.1019607843)",
                                                    }}
                                                    searchClass="none"
                                                />
                                            )}
                                        />
                                        <span className="text-danger">{errors.phone && errors.phone.message}</span>
                                    </Col>
                                </Row>
                                <Row className="g-3 pt-3">
                                    <Col md="6">
                                        <Label htmlFor="address">{Address}</Label>
                                        <textarea className="form-control" type="text" {...register("address")} />
                                    </Col>
                                    <Col md="6">
                                        <Label className="form-label">Logo</Label>
                                        <Controller
                                            name="logo"
                                            control={control}
                                            render={({ field }) => (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Col md="8">
                                                        <input
                                                            {...register("logo")}
                                                            className="form-control fileInputStyle"
                                                            type="file"
                                                            onChange={handleLogoChange}
                                                            accept=".png, .jpg, .jpeg"
                                                        />
                                                    </Col>
                                                    <Col md="4">
                                                        <span className="imagestyle">
                                                            {logo && <img
                                                                src={window.URL.createObjectURL(logo)}
                                                                alt="logo"
                                                                width="40px"
                                                                height="40px"
                                                            />
                                                            }
                                                        </span>
                                                    </Col>
                                                </div>
                                            )}
                                        />
                                    </Col>
                                    <div className="text-end  ">
                                        <Button className="tab-button" onClick={nextTab}>{Next}</Button>
                                    </div>
                                </Row>
                            </CardBody>
                        </TabPane>

                        <TabPane tabId="2">
                            <CardHeader className="card-text-color pb-0">
                                <H3>{Localisation}</H3>
                            </CardHeader>

                            <CardBody>
                                <Row className="g-3">
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="financial">{FinancialYearStart}</Label>
                                            <DatePicker
                                                style={{ padding: '12px 100px' }}
                                                className="form-control "
                                                dateFormat="dd/MM/yyyy"
                                                placeholderText="dd/mm/yyyy"
                                                selected={financialDate}
                                                onChange={handleChangeDate}
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="time">{TimeZone}</Label>
                                            <Controller
                                                name="timezone"
                                                control={control}
                                                render={({ field }) => (
                                                    <Typeahead
                                                        options={asiaTimezones}
                                                        onChange={(value) => {
                                                            field.onChange(value);
                                                        }}
                                                        value={field.value}
                                                    />
                                                )}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="g-3">
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="dateFormate">{DateFormate}</Label>
                                            <Controller
                                                name="dateFormate"
                                                control={control}
                                                render={({ field }) => (
                                                    <Typeahead
                                                        id="dateFormate"
                                                        options={dateformatOptions}
                                                        onChange={(value) => {
                                                            field.onChange(value);
                                                        }}
                                                        value={field.value}
                                                    />
                                                )}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <Label htmlFor="separator">{Separator}</Label>
                                        <Controller
                                            name="separator"
                                            control={control}
                                            render={({ field }) => (
                                                <Typeahead
                                                    options={specialCharacterOptions}
                                                    onChange={(value) => {
                                                        field.onChange(value);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <Row className="g-3">
                                    <Col md="6">
                                        <Label htmlFor="confirmPW">{PerPosition}</Label>
                                        <Controller
                                            name="position"
                                            control={control}
                                            render={({ field }) => (
                                                <Typeahead
                                                    options={positionOptions}
                                                    onChange={(value) => {
                                                        field.onChange(value);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md="6">
                                        <Label htmlFor="confirmPW">{Decimal}</Label>
                                        <Controller
                                            name="decimal"
                                            control={control}
                                            defaultValue={decimalOptions[0]}
                                            render={({ field }) => (
                                                <Typeahead
                                                    options={decimalOptions}
                                                    onChange={(value) => {
                                                        field.onChange(value);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <div className="text-end mt-2 ">
                                    <Button className="tab-button" onClick={nextTab}>{Next}</Button>
                                </div>
                            </CardBody>
                        </TabPane>

                        <TabPane tabId="3">

                            <CardHeader className="card-text-color pb-0">
                                <H3>{Invoice}</H3>
                            </CardHeader>

                            <CardBody>
                                <Row className="g-3">
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="prefix">{Invoiceprefix}</Label>
                                            <input className="form-control" type="text" name="inv-prefix"
                                                {...register("invprefix")}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="suffix">{Invoicesiffix}</Label>
                                            <input className="form-control" type="number" min={1} defaultValue={1}
                                                name="inv-suffix"
                                                {...register("invsuffix", { value: 1 })}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row className="g-3">
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="quantityname">{QuantityName}</Label>
                                            <Controller
                                                name="quantityname"
                                                control={control}
                                                render={({ field }) => (
                                                    <Typeahead
                                                        id="quantityname"
                                                        options={quantityOptions}
                                                        onChange={(value) => {
                                                            field.onChange(value);
                                                        }}
                                                        value={field.value}
                                                    />
                                                )}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <Label htmlFor="duedate">{DefaultDueDays}</Label>
                                        <Controller
                                            name="duedate"
                                            control={control}
                                            defaultValue={duedateOptions[0]}
                                            render={({ field }) => (
                                                <Typeahead
                                                    options={duedateOptions}
                                                    onChange={(value) => {
                                                        field.onChange(value);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <Label className="form-label">Icon</Label>
                                        <Controller
                                            name="icon"
                                            control={control}
                                            render={({ field }) => (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Col md="8">
                                                        <input
                                                            {...register("icon")}
                                                            className="form-control fileInputStyle"
                                                            type="file"
                                                            onChange={handleIconChange}
                                                            accept=".png, .jpg, .jpeg"
                                                        />
                                                    </Col>
                                                    <Col md="4">
                                                        <span className="imagestyle">
                                                            {icon &&
                                                                <img
                                                                    src={URL.createObjectURL(icon)}
                                                                    alt="icon"
                                                                    width="40px"
                                                                    height="40px"
                                                                />
                                                            }
                                                        </span>
                                                    </Col>
                                                </div>
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <div className="text-end">
                                    <Button className="tab-button" onClick={nextTab}>{Next}</Button>
                                </div>
                            </CardBody>
                        </TabPane>

                        <TabPane tabId="4">

                            <CardHeader className="card-text-color pb-0">
                                <H3>{Defaults}</H3>
                            </CardHeader>

                            <CardBody>
                                <Row className="g-3">
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="language">{DefaultLang}</Label>
                                            <Controller
                                                name="language"
                                                control={control}
                                                defaultValue={languageOptions[0]}
                                                render={({ field }) => (
                                                    <Typeahead
                                                        id="language"
                                                        options={languageOptions}
                                                        onChange={(value) => {
                                                            field.onChange(value);
                                                        }}
                                                        value={field.value}
                                                    />
                                                )}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <Label htmlFor="currency">{DefaultCurrency}</Label>
                                        <Controller
                                            name="currency"
                                            control={control}
                                            defaultValue={currencyOptions[0]}
                                            render={({ field }) => (
                                                <Typeahead
                                                    options={currencyOptions}
                                                    onChange={(value) => {
                                                        field.onChange(value);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <div className="pt-3 text-end">
                                    <span className="ps-2 spinner-enable">
                                        <Button className="btn" type="submit">{Submit}</Button>
                                    </span>
                                </div>
                            </CardBody>
                        </TabPane>
                    </TabContent>
                </Card>
            </Form>
        </Fragment>
    );
};

export default Companyadd;
