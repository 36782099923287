import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { getpaymentId, getpaymentIdRole } from '../../../CommonUrl';
import { useSelector } from 'react-redux';
import { getClientUrlDataToken } from '../../../Utils/restUtils';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Transparentspinner from '../../Common/Loader/Transparentspinner';
import { Col, Media, Row } from 'reactstrap';
import { IssuedMay, PaymentDue, Email, Mob, TaxIn, Txn, SalesAgent } from '../../../Constant';
import { H3, H2, P, Image, H4 } from '../../../AbstractElements';

const RenewSub = () => {
    const [spinnerTrans, setspinnerTrans] = useState(true);
    const authState = useSelector((state) => state.auth);
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("payment_id");
    const [apidata, setapidata] = useState('');
    const navigate = useNavigate();
    const [invoice, setinvoice] = useState(null);
    const imageUrl = apidata.company_logo_url
        ;

    useEffect(() => {
        handlePayment();
    }, []);

    const componentRef = useRef(null);
    const componentRefFullPage = useRef(null); // For full-page print

    // Thermal Print handler
    const handlePrintThermal = useReactToPrint({
        content: () => componentRef.current,
    });

    // Full-Page Print handler
    const handlePrintFullPage = useReactToPrint({
        content: () => componentRefFullPage.current,
    });

    const handlePayment = async () => {
        const get_api = await getClientUrlDataToken(
            authState.apiUrl,
            authState.userRole === 1 ? getpaymentId + `?payment_id=${param1}` : getpaymentIdRole + `?payment_id=${param1}`

        );
        if (get_api.status === true) {
            setapidata(get_api.payment);
            const invoices_map = get_api.payment.invoices_reflected.map((i) => i).join(', ');
            setinvoice(invoices_map);
        } else if (get_api.status >= 400 && get_api.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (get_api.status >= 500 && get_api.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setspinnerTrans(false);
    };

    return (
        <Fragment>
            {spinnerTrans && <Transparentspinner />}
            <style>
                {`
                    @media print {
                        @page {
                            size: A4;
                            margin: 0;
                        }
                        body {
                            margin: 0;
                            padding: 0;
                        }

                        /* Thermal Print */
                        .thermal-print {
                            width: 290px;
                            margin: 0;
                             position: absolute;
                              top: 0;
                              left: 0;
                               padding: 10px;
                            text-align: left;
                           
                        }
                        .thermal-print hr {
                            margin-top: -4px !important;
                        }

                        /* Full-page Print */
                        .full-page-print {
                            width: 100%;
                            padding: 20px;
                            text-align: left;
                        }
                        .full-page-print hr {
                            margin-top: 10px !important;
                        }

                        .detail-item {
                            display: flex;
                            justify-content: space-between;
                        }
                        .detail-item span {
                            text-align: left;
                        }
                    }
                `}
            </style>

            <div className="print-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                <div className="" style={{ backgroundColor: 'white', padding: '12px', borderRadius: '8px' }}>

                    {/* Thermal Print Section */}
                    <div className="receipts thermal-print " style={{ textAlign: 'left' }} ref={componentRef}>
                        <hr className="hr-line" />
                        <div style={{ textAlign: 'center' }}>
                            <h2>{apidata.company_name}</h2>
                            <h3>+{apidata.company_country_code + " " + apidata.company_mobile}</h3>
                            <hr className="hr-line" style={{ marginTop: '5px' }} />
                            <h4>PAYMENT RECEIPT</h4>
                            <hr className="hr-line" style={{ marginTop: '5px' }} />
                        </div>
                        <h6>Customer Details:</h6>
                        <div className="customer-add">
                            <p className="customer-name">{apidata.customer_name}</p>
                            <div className="customer-address" 
                            style={{
                                // wordWrap: 'break-word',
                                // overflowWrap: 'break-word',
                                // whiteSpace: 'normal',
                                width:'1.5rem', 
                              }}
                              >
                                <p>{apidata.customer_address}</p>
                            </div>
                        </div>
                        <hr className="hr-line" style={{ marginTop: '10px' }} />
                        <div className="details-container">
                            <div className="detail-item">
                                <p className="left">Subscriber id# </p>
                                {authState.userRole === 1 || authState.permissions.includes('show-customer-profile') ? (
                                    <Link to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${apidata.customer_id}`}>
                                        <span className="right text-decoration-underline">{apidata.subscriber_id}</span>
                                    </Link>
                                ) : (
                                    apidata.subscriber_id
                                )}
                            </div>
                            <div className="detail-item">
                                <p className="left">Receipt No# </p><span className="right">{apidata.receipt_no}</span>
                            </div>
                            <div className="detail-item">
                                <p className="left">Txn id# </p><span className="right">{apidata.payment}</span>
                            </div>
                            <div className="detail-item">
                                <p className="left">Transaction Date: </p><span className="right">{apidata.transaction_date}</span>
                            </div>
                            <div className="detail-item">
                                <p className="left">Payment Method: </p><span className="right">{apidata.payment_method_name}</span>
                            </div>
                            <div className="detail-item">
                                <p className="left">Sales agent: </p><span className="right">{apidata.sales_agent}</span>
                            </div>
                        </div>
                        <hr className="hr-line" style={{ marginTop: '-1px' }} />
                        <div className="paid-amount">
                            <p className="left">Paid Amount: </p><span className="right">{authState.currency} {apidata.paid_amount}</span>
                        </div>
                        <hr className="hr-line" style={{ marginTop: '-5px' }} />
                        <div className="invoice-details">
                            <div className="detail-item">
                                <p className="left">Invoices reflected: </p><span className="right">{invoice}</span>
                            </div>
                            {apidata.description != null && (
                                <div className="detail-item">
                                    <p className="left">Description:</p><span className="right">{apidata.description}</span>
                                </div>
                            )}
                            {apidata.reference !== null && (
                                <div className="detail-item">
                                    <p className="left">Ref:</p><span className="right">{apidata.reference}</span>
                                </div>
                            )}
                            <div className="detail-item">
                                <p className="left">Total Dues:</p><span className="right">{authState.currency} {apidata.total_dues}</span>
                            </div>
                            <div className="detail-item">
                                <p className="left">Wallet Money</p><span className="right">{authState.currency} {apidata.wallet_money}</span>
                            </div>
                        </div>
                        {apidata.next_payment_date !== '' && (
                            <div className="hypen mt-2">
                                <p className="thank-you">Your next Due/Billing date is on {apidata.next_payment_date}</p>
                            </div>
                        )}
                        <p>Thank you</p>
                        <p className="hyphen" style={{ textAlign: 'center' }}>*******</p>
                    </div>

                    {/* Full Page Print Section */}
                    <div className='' style={{ display: 'none' }}>
                        <div className="receipts full-page-print" ref={componentRefFullPage}>
                            <div style={{ textAlign: 'center' }}>
                                <Row className="d-flex justify-content-between align-items-stretch">
                                
                                    <Col sm="2" className="custom-col text-center">
                                        <Media className="h-100">
                                            <div className="d-flex-center">
                                                <Image
                                                    attrImage={{
                                                        className: 'd-flex-object img-60',
                                                        src: imageUrl,
                                                        alt: ''
                                                    }}
                                                    style={{ width: '100%', height: 'auto' }} 
                                                />
                                            </div>
                                        </Media>
                                    </Col>

                                  
                                    <Col sm="5" className="custom-col text-start">
                                        <Media className="flex-grow-1">
                                            <div>
                                                <H2
                                                    attrH2={{
                                                        className: 'media-heading text-left f-w-600 company-name',
                                                        style: { fontSize: '18px' }
                                                    }}
                                                >
                                                    {apidata.company_name}
                                                </H2>

                                                <P>
                                                    <span className="digits">{apidata.company_address}</span><br />
                                                    {apidata.company_email}
                                                    <br />
                                                    <span className="digits">+{`${apidata.company_country_code}${apidata.company_mobile}`}</span>
                                                </P>
                                            </div>
                                        </Media>
                                    </Col>

                                   
                                    <Col sm="5" className="custom-col text-end">
                                        <div className="text-end">
                                            <h5>
                                                <span className="digits counter">Bill To: </span>
                                            </h5>
                                            <P>
                                                <strong>{apidata.customer_name}</strong> <br />
                                                {apidata.customer_address}<br />
                                                {Mob} : + {apidata.customer_number}<br />
                                                {Email} : {apidata.customer_email}<br />
                                                {TaxIn} : {apidata.transaction_id}<br />
                                            </P>
                                        </div>
                                    </Col>
                                </Row>


                                <hr className="hr-line" style={{ marginTop: '5px' }} />
                                <h4>PAYMENT RECEIPT</h4>
                                <hr className="hr-line" style={{ marginTop: '5px' }} />
                            </div>
                            <div className="details-container">
                                <div className="detail-item">
                                    <p className="left">Subscriber id# </p>
                                    {authState.userRole === 1 || authState.permissions.includes('show-customer-profile') ? (
                                        <Link to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${apidata.customer_id}`}>
                                            <span className="right text-decoration-underline">{apidata.subscriber_id}</span>
                                        </Link>
                                    ) : (
                                        apidata.subscriber_id
                                    )}
                                </div>
                                <div className="detail-item">
                                    <p className="left">Receipt No# </p><span className="right">{apidata.receipt_no}</span>
                                </div>
                                <div className="detail-item">
                                    <p className="left">Txn id# </p><span className="right">{apidata.payment}</span>
                                </div>
                                <div className="detail-item">
                                    <p className="left">Transaction Date: </p><span className="right">{apidata.transaction_date}</span>
                                </div>
                                <div className="detail-item">
                                    <p className="left">Payment Method: </p><span className="right">{apidata.payment_method_name}</span>
                                </div>
                                <div className="detail-item">
                                    <p className="left">Sales agent: </p><span className="right">{apidata.sales_agent}</span>
                                </div>
                            </div>
                            <hr className="hr-line" style={{ marginTop: '-1px' }} />
                            <div className="paid-amount">
                                <p className="left">Paid Amount: </p><span className="right">{authState.currency} {apidata.paid_amount}</span>
                            </div>
                            <hr className="hr-line" style={{ marginTop: '-5px' }} />
                            <div className="invoice-details">
                                <div className="detail-item">
                                    <p className="left">Invoices reflected: </p><span className="right">{invoice}</span>
                                </div>
                                {apidata.description != null && (
                                    <div className="detail-item">
                                        <p className="left">Description:</p><span className="right">{apidata.description}</span>
                                    </div>
                                )}
                                {apidata.reference !== null && (
                                    <div className="detail-item">
                                        <p className="left">Ref:</p><span className="right">{apidata.reference}</span>
                                    </div>
                                )}
                                <div className="detail-item">
                                    <p className="left">Total Dues:</p><span className="right">{authState.currency} {apidata.total_dues}</span>
                                </div>
                                <div className="detail-item">
                                    <p className="left">Wallet Money</p><span className="right">{authState.currency} {apidata.wallet_money}</span>
                                </div>
                            </div>
                            {apidata.next_payment_date !== '' && (
                                <div className="hypen mt-2">
                                    <p className="thank-you">Your next Due/Billing date is on {apidata.next_payment_date}</p>
                                </div>
                            )}
                            <p>Thank you</p>
                            <p className="hyphen" style={{ textAlign: 'center' }}>*******</p>
                        </div>

                    </div>
                    <hr className="hr-line" style={{ marginTop: '-5px' }} />
                    <div className="button-print text-center">
                        <button className="print-button" onClick={handlePrintThermal}>Thermal Print</button>
                        <button className="print-button" onClick={handlePrintFullPage}>Print</button>

                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default RenewSub;
