import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, Breadcrumbs, Btn, H4 } from '../../AbstractElements';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken } from '../../Utils/restUtils';
import { GstRep, Search, Category, Filter, Export } from '../../Constant';
import { viewTaxReport, viewTaxReportRole, getCategory, getCategoryRole } from '../../CommonUrl';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import Transparentspinner from '../Common/Loader/Transparentspinner';

const GstReport = () => {
    const { register, handleSubmit } = useForm({});
    const authState = useSelector((state) => state.auth);
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const sortedBy = params.get("sortBy") || '';
    const sortedOrder = params.get("sortOrder") || '';
    const searchKey = params.get('search') || '';
    const start_date = params.get('start_date') || '';
    const end_date = params.get('end_date') || '';
    const category = params.get('category') || '';
    const navigate = useNavigate();
    const [taxLists, setTaxList] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [taxColumn, setTaxColumn] = useState([]);
    const [dateRange, setDateRange] = useState([null, null]);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [startDate, endDate] = dateRange;
    const [sortConfig, setSortConfig] = useState({ column: sortedBy, order: sortedOrder });
    const [filters, setFilters] = useState({
        searchKey: searchKey,
        start_date: start_date,
        end_date: end_date,
        category: category
    });

    const dynamicColumns = taxColumn.map((columnName) => ({
        name: <H4>{columnName}</H4>,
        id: columnName,
        selector: (row) => authState.currency + row[columnName], // Selector function to get value from row object
        sortable: true,
        center: false,
        wrap: true,
    }));

    const GstColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            center: false,
            width: '80px',
            wrap: true,
        },
        {
            name: <H4>Invoice Date</H4>,
            id: 'invoice_date',
            selector: row => row.invoiceDate,
            sortable: true,
            center: false,
            width: "130px",
            wrap: true,
        },
        {
            name: <H4>Invoice No.</H4>,
            id: 'invoice_no',
            selector: row =>
                <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile/invoice?invoice_id=${row.order_id}&customer_id=${row.customer_id}`}>
                    {row.invoiceNo}
                </Link>,
            sortable: true,
            wrap: true,
            center: false,
        },
        {
            name: <H4>Subscriber Id</H4>,
            id: 'subscriber_id',
            selector: row =>
                (authState.userRole === 1 || authState.permissions.includes('show-customer-profile')) ?
                    <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${row.customer_id}`}>
                        {row.SubId}
                    </Link> : row.SubId,
            sortable: true,
            wrap: true,
            center: false,
        },
        {
            name: <H4>Customer</H4>,
            id: 'customer',
            selector: row => row.Customer,
            sortable: true,
            center: false,
            wrap: true
        },
        {
            name: <H4>Address</H4>,
            id: 'address',
            selector: row => row.Address,
            sortable: true,
            center: false,
            width: "200px",
            wrap: true
        },
        {
            name: <H4>SAC</H4>,
            id: 'sac',
            selector: row => row.Sac,
            sortable: true,
            center: false,
        },
        {
            name: <H4>Rate</H4>,
            id: 'rate',
            selector: row => row.rate,
            sortable: true,
            center: false,
        },
        {
            name: <H4>DISC</H4>,
            id: 'discount',
            selector: row => row.Disc,
            sortable: true,
            center: false,
        },
        ...dynamicColumns,
        {
            name: <H4>Total</H4>,
            id: 'total',
            selector: row => row.Total,
            sortable: true,
            center: false,
        }, {
            name: <H4>Tax In</H4>,
            id: 'tax_in',
            selector: row => row.TaxIn,
            sortable: true,
            center: false,
            wrap: true
        },
    ];

    useEffect(() => {
        fetchTax(1);
    }, []);

    const handleSort = (column) => {
        let newOrder = 'asc';
        if (sortedBy === column.id) {
            newOrder = sortedOrder === 'asc' ? 'desc' : 'asc';
            setSortConfig({ column: column.id, order: newOrder });
            navigate(`/report/tax-report?search=${filters.searchKey}&start_date=${filters.start_date}&end_date=${filters.end_date}&sortBy=${column.id}&sortOrder=${newOrder}`);
        } else {
            newOrder = sortedOrder === 'asc' ? 'desc' : 'asc';
            setSortConfig({ column: column.id, order: newOrder });
            navigate(`/report/tax-report?search=${filters.searchKey}&start_date=${filters.start_date}&end_date=${filters.end_date}&sortBy=${column.id}&sortOrder=${newOrder}`);
        }
    };

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleApiError = (status) => {
        if (status >= 400 && status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (status >= 500 && status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const handlePageChange = page => {
        fetchTax(page, perPage, filters.searchKey, filters.start_date, filters.end_date, filters.category, sortConfig.column, sortConfig.order);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchTax(page, newPerPage, filters.searchKey, filters.start_date, filters.end_date, filters.category, sortConfig.column, sortConfig.order);
        setPerPage(newPerPage);
    };

    const onSubmit = async (data) => {
        const search = data.search;
        const category = data.category;
        const start_date = startDate ? moment(startDate).format("DD-MM-YYYY") : '';
        const end_date = endDate ? moment(endDate).format("DD-MM-YYYY") : '';
        const queryParams = new URLSearchParams({
            search: search,
            start_date,
            end_date,
            category:category,
            sortBy: sortConfig.column || '',
            sortOrder: sortConfig.order || '',
        }).toString();
        navigate(`/report/tax-report?${queryParams}`);
        setFilters({
            search,
            start_date,
            end_date,
            category
        });
        fetchTax(currentPage, perPage, search, start_date, end_date, category);
    };

    const fetchTax = async (page, size = perPage) => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ?
            viewTaxReport + `?page=${page}&page_length=${size}&search=${filters.searchKey ? filters.searchKey : ''}
            &start_date=${filters.start_date ? filters.start_date : ''}&end_date=${filters.end_date ? filters.end_date : ''}
            &sort_by=${sortConfig.column ? sortConfig.column : ''}&sort_order=${sortConfig.order ? sortConfig.order : ''}` :
            viewTaxReportRole + `?page=${page}&page_length=${size}&search=${filters.searchKey ? filters.searchKey : ''}
            &start_date=${filters.start_date ? filters.start_date : ''}&end_date=${filters.end_date ? filters.end_date : ''}
            &sort_by=${sortConfig.column ? sortConfig.column : ''}&sort_order=${sortConfig.order ? sortConfig.order : ''}`);
        if (response.status === true) {
            setTaxColumn(response.data.taxes);
            setResetPaginationToggle(!resetPaginationToggle);
            const valueTaxes = response.data.tax_data.data;
            const SerialNo = (page - 1) * size + 1;
            setTaxList(
                valueTaxes.map((e, index) => {
                    const row = {
                        Si: SerialNo + index,
                        invoiceDate: e.invoice_date,
                        invoiceNo: e.invoice_number,
                        SubId: e.subscriber_id,
                        Customer: e.customer,
                        Address: e.address,
                        Sac: e.sac,
                        rate: authState.currency + e.rate,
                        Disc: authState.currency + e.discount,
                        Total: authState.currency + e.total,
                        TaxIn: e.tax_in,
                        customer_id: e.customer_id,
                        order_id: e.order_id
                    };
                    response.data.taxes.forEach((columnName) => {
                        row[columnName] = e[columnName];
                    });
                    return row;
                })
            );
            setTotalRows(response.data.tax_data.total);
        } else {
            handleApiError(response.status)
        }
        setLoading(false);
    };

    const exportTaxReportExcel = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(
            authState.apiUrl,
            authState.userRole === 1
                ? viewTaxReport + `?export=addon_summary_report&search=${filters?.searchKey ?? ''}&start_date=${filters?.start_date ?? ''}&end_date=${filters?.end_date ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`
                : viewTaxReportRole + `?export=addon_summary_report&search=${filters?.searchKey ?? ''}&start_date=${filters?.start_date ?? ''}&end_date=${filters?.end_date ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`
        );
        if (response.status === true) {
            const { tax_data, taxes } = response.data;
            const headers = [
                "Invoice Date",
                "Invoice No",
                "Subscription Id",
                "Customer",
                "Address",
                "SAC",
                "Rate",
                "Discount",
                ...taxes, // Add dynamic tax headers
                "Total",
                "Tax In",
            ];

            const dataToExport = [
                // Create header row
                headers.reduce((acc, header) => ({ ...acc, [header]: header }), {}),
                // Map the data rows
                ...tax_data.map(({ invoice_date, invoice_number, subscriber_id, customer, address, sac, rate, discount, total, tax_in, ...taxValues }) => ({
                    "Invoice Date": invoice_date,
                    "Invoice No": invoice_number,
                    "Subscription Id": subscriber_id,
                    "Customer": customer,
                    "Address": address,
                    "SAC": sac,
                    "Rate": rate,
                    "Discount": discount,
                    ...Object.fromEntries(taxes.map(tax => [tax, taxValues[tax] || ''])), // Add dynamic tax values
                    "Total": total,
                    "Tax In": tax_in,
                }))
            ];
            const ws = XLSX.utils.json_to_sheet(dataToExport, { skipHeader: true });
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Tax report');
            XLSX.writeFile(wb, 'Tax_report.xlsx');
        } else {
            handleApiError(response.status);
        }
        setLoading(false);
    };


    const exportTaxReportPdf = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewTaxReport +
            `?export=addon_summary_report&search=${filters?.searchKey ?? ''}&start_date=${filters?.start_date ?? ''}&end_date=${filters?.end_date ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}` :
            viewTaxReportRole + `?export=addon_summary_report&search=${filters?.searchKey ?? ''}&start_date=${filters?.start_date ?? ''}&end_date=${filters?.end_date ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`);
        if (response.status === true) {
            const doc = new jsPDF();
            const { tax_data, taxes } = response.data;
            const columns = [
                "Sl",
                "Invoice Date",
                "Invoice No",
                "Subscription Id",
                "Customer",
                "Address",
                "SAC",
                "Rate",
                "Discount",
                ...taxes, // Add dynamic tax headers
                "Total",
                "Tax In",
            ];

            const rows = tax_data.map(({
                invoice_date, invoice_number, subscriber_id, customer, address, sac, rate, discount, total, tax_in, ...taxValues
            }, index) => [
                    index + 1,
                    invoice_date,
                    invoice_number,
                    subscriber_id,
                    customer,
                    address,
                    sac,
                    rate,
                    discount,
                    ...taxes.map(tax => taxValues[tax] || ''), // Add dynamic tax values
                    total,
                    tax_in,
                ]);
            doc.autoTable({
                head: [columns],
                body: rows
            });
            doc.save("Tax_report.pdf");
        } else {
            handleApiError(response.status)
        }
        setLoading(false);
    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={GstRep} parent="Reports" title={GstRep} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -25px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{GstRep}</H3>
                    <div>
                        {(authState.userRole === 1 || authState.permissions.includes('export-report')) &&
                            <CardBody className="dropdown-basic">
                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                    <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                        {Export} <i className="icofont icofont-arrow-up"></i>
                                    </DropdownToggle>
                                    <DropdownMenu container="body">
                                        <DropdownItem style={toggleStyle} onClick={() => exportTaxReportExcel()} ><i className="fa fa-file-excel-o me-2"></i>Excel</DropdownItem>
                                        <DropdownItem style={toggleStyle} onClick={() => exportTaxReportPdf()}><i className="fa fa-file-pdf-o me-2"></i>Pdf</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </CardBody>
                        }
                    </div>
                </CardHeader>

                <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Row className="ps-3 gap-sm-3 gap-md-2 filterGap">
                        <Col sm="12" md='5' lg='2'>
                            <input className="form-control" placeholder={Search} type="text"
                                {...register('search')} />
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <div style={{ position: 'relative', zIndex: 2 }}>
                                <DatePicker
                                    className="form-control digits w-100"
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    isClearable={true}
                                    monthsShown={2}
                                    popperPlacement="bottom"
                                    calendarClassName='parallel-calendar'
                                    placeholderText='Select Date Range'
                                />
                            </div>

                        </Col>
                        <Col sm="12" md="5" lg='1' className="custom-filtrs">
                            <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                        </Col>
                    </Row>
                </Form>
                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={GstColumns}
                            data={taxLists}
                            onSort={handleSort} // Attach sorting handler here
                            sortServer // Enable server-side sorting
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default GstReport;