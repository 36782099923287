import React from 'react';
import Select from 'react-select';

const Typeahead = (props) => {
  return (
    <Select
      id={props.id}
      options={props.options}
      onChange={(selectedOption) => props.onChange(selectedOption)}
      onInputChange={(inputValue) => props.onInputChange?.(inputValue)}
      value={props.value}
      placeholder={props.placeholder || 'Select...'}
      isDisabled={props.disabled}
      className="col-sm-12"
      formatOptionLabel={(option) => option.displayLabel || option.label} // Display JSX
      filterOption={undefined}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          height: 45,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 1000,
        }),
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: 'rgba(83, 70, 134, 0.474)',
          primary: '#534686',
          primary50: 'rgba(83, 70, 134, 0.3)'
        },
      })}
    />
  );
}

export default Typeahead;