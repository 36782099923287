import React, { useState, useEffect } from 'react';
import { Save, Amount, PaidDate, PaymentMethod } from '../../Constant';
import { Col, Form, Label, Row, FormGroup } from 'reactstrap';
import { useForm, Controller } from "react-hook-form";
import { Btn } from '../../AbstractElements';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../Utils/restUtils';
import { useSelector } from 'react-redux';
import { addExpensePayment, addExpensePaymentRole, viewPayment, viewPaymentRole } from '../../CommonUrl';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from "@hookform/resolvers/yup";
import Typeahead from "../../CommonElements/TypeAhead/index";
import * as yup from "yup";
import moment from "moment";

const schema = yup.object().shape({
    amount: yup.string().required().label('Amount'),
    payment_method: yup.mixed().required().label('Payment method'),
})

const ExpensePay = ({ expenseId }) => {
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema),
    });
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const [loading, setloading] = useState(false);
    const authstate = useSelector((state) => state.auth);
    const [payDate, setPayDate] = useState(new Date());
    const [payment, setPayment] = useState([]);
    const [paymentOption, setPaymentOption] = useState([]);

    const handleChangeDate = date => {
        setPayDate(date);
    };
    useEffect(() => {
        paymentData();
        let parseBalance = (expenseId.Balance).replace(/,/g, "");
        setValue("amount", parseBalance);
    }, []);

    useEffect(() => {
        setPaymentOption(
            payment.map((data) => ({
                value: data.id,
                label: data.name,
            }))
        );
    }, [payment]);

    const paymentData = async () => {
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewPayment : viewPaymentRole);

        if (data.status === true) {
            setPayment(data.data);
        }
        else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
    };

    const onSubmit = async (data) => {
        setloading(true)
        const postResponse = {
            expense_id: expenseId.id,
            amount: data.amount,
            paid_date: moment(payDate).format("YYYY-MM-DD HH:mm:ss"),
            payment_method: data.payment_method.value

        }

        const addHeader = await postClientUrlWithToken(authstate.apiUrl, authState.userRole === 1 ? addExpensePayment : addExpensePaymentRole, postResponse);
        if (addHeader.status === 200) {
            if (addHeader.data.status === true) {
                navigate(`${process.env.PUBLIC_URL}/Expenses/Expenses`);
                toast.success(addHeader.data.message);
                // setModal(false)
            } else {
                setloading(false)
                toast.error(addHeader.data.message);
            }
        } else if (addHeader.status >= 400 && addHeader.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (addHeader.status >= 500 && addHeader.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setloading(false)
    }

    return (
        <>
            <Form
                className="needs-validation"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >

                <Row className="g-3 pt-3">

                    <Col md='6'>
                        <Label className="form-label" >{Amount} <span className="requireStar">*</span></Label>
                        <input className="form-control" id="amount" type="number" min="0"{...register('amount', { required: true })} />
                        <p className="text-danger">{errors.amount?.message}</p>
                    </Col>

                    <Col md="6">
                        <FormGroup className="mb-3">
                            <Label className="form-label">{PaidDate} <span className="requireStar">*</span></Label>
                            <Col xl='12' md="12" sm='12'>
                                <DatePicker
                                    id="paid_date"
                                    style={{ padding: '12px 100px' }}
                                    className="form-control "
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="dd/mm/yyyy"
                                    selected={payDate}
                                    onChange={handleChangeDate}
                                    required
                                />
                            </Col>

                        </FormGroup>
                    </Col>

                </Row>
                <Row className="g-3 pt-3">
                    <Col md="6">
                        <Label className="form-label" >
                            {PaymentMethod} <span className="requireStar">*</span>
                        </Label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Controller
                                name="payment_method"
                                control={control}
                                render={({ field }) => (
                                    <Typeahead
                                        placeholder="select..."
                                        options={paymentOption}
                                        onChange={(value) => {
                                            field.onChange(value);
                                        }}
                                        value={field.value}
                                    />
                                )}
                            />

                        </div>
                        <p className="text-danger">{errors.payment_method?.message}</p>
                    </Col>

                </Row>
                <FormGroup></FormGroup>
                <span className='ps-2 spinner-enable'>
                    <Btn attrBtn={{ color: "primary", className: "btn" }} type="submit">
                        {Save}
                    </Btn>
                </span>

            </Form>
        </>
    )
}

export default ExpensePay