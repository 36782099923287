import React from 'react';
import { H4 } from '../../AbstractElements';

export const PackageSummaryColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        center: false,
        width: '80px'
    },
    {
        name: <H4>Packages</H4>,
        id: 'package_name',
        selector: row => row.Packages,
        sortable: true,
        center: false,
        wrap: true,
    },

    {
        name: <H4>Unit Price</H4>,
        id: 'unit_price',
        selector: row => row.unitPrice,
        sortable: true,
        center: false,
        wrap: true,
    },
    {
        name: <H4>Unit Tax</H4>,
        id: 'unit_tax',
        selector: row => row.unitTax,
        sortable: true,
        center: false,
        wrap: true,

    },
    {
        name: <H4>Total Price</H4>,
        id: 'total_price',
        selector: row => row.totalPrice,
        sortable: true,
        center: false,
        wrap: true,
    },
    {
        name: <H4>Total Tax</H4>,
        id: 'total_tax',
        selector: row => row.totalTax,
        sortable: true,
        center: false,
        wrap: true,
    },
    {
        name: <H4>Grand Total</H4>,
        id: 'grand_total',
        selector: row => row.grandTotal,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Number of invoices</H4>,
        id: 'invoice_count',
        selector: row => row.invoiceNo,
        sortable: true,
        center: true,
    },
];

export const GstColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: false,
        wrap: true,
    },
    {
        name: <H4>Invoice Date</H4>,
        selector: row => row.invoiceRate,
        sortable: true,
        center: false,
        width: "130px",
        wrap: true,
    },
    {
        name: <H4>Invoice No.</H4>,
        selector: row => row.invoiceNo,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Subscriber Id</H4>,
        selector: row => row.SubId,
        sortable: true,
        center: false,
    },

    {
        name: <H4>Customer</H4>,
        selector: row => row.Customer,
        sortable: true,
        center: false,
        wrap: true
    },

    {
        name: <H4>Address</H4>,
        selector: row => row.Address,
        sortable: true,
        center: false,
        wrap: true
    },
    {
        name: <H4>SAC</H4>,
        selector: row => row.Sac,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Rate</H4>,
        selector: row => row.rate,
        sortable: true,
        center: false,
    },
    {
        name: <H4>DISC</H4>,
        selector: row => row.Disc,
        sortable: true,
        center: false,
    },
    {
        name: <H4>CGST</H4>,
        selector: row => row.Cgst,
        sortable: true,
        center: false,
    },
    {
        name: <H4>SGST</H4>,
        selector: row => row.Sgst,
        sortable: true,
        center: false,
    },
    {
        name: <H4>IGST</H4>,
        selector: row => row.Igst,
        sortable: true,
        center: false,
    },
    {
        name: <H4>CESS</H4>,
        selector: row => row.Cess,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Total</H4>,
        selector: row => row.Total,
        sortable: true,
        center: false,
    }, {
        name: <H4>GST In</H4>,
        selector: row => row.GstIn,
        sortable: true,
        center: false,
    },
];

export const AddonSummaryColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        center: false,
        width: '80px'
    },
    {
        name: <H4>Packages</H4>,
        id: 'add_on_name',
        selector: row => row.Packages,
        sortable: true,
        center: false,
    },

    {
        name: <H4>Unit Price</H4>,
        id: 'unit_price',
        selector: row => row.unitPrice,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Unit Tax</H4>,
        id: 'unit_tax',
        selector: row => row.unitTax,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Total Price</H4>,
        id: 'total_price',
        selector: row => row.totalPrice,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Total Tax</H4>,
        id: 'total_tax',
        selector: row => row.totalTax,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Grand Total</H4>,
        id: 'grand_total',
        selector: row => row.grandTotal,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Number of Invoices</H4>,
        id: 'invoice_count',
        selector: row => row.invoiceNo,
        sortable: true,
        // center: true,
    },
];



export const ExpenseSummaryColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: false,
        width: '80px'
    },
    {
        name: <H4>Category</H4>,
        selector: row => row.Category,
        sortable: true,
        center: false,
    },

    {
        name: <H4>January</H4>,
        selector: row => row.January,
        sortable: true,
        center: false,
    },
    {
        name: <H4>February</H4>,
        selector: row => row.February,
        sortable: true,
        center: false,
    },
    {
        name: <H4>March</H4>,
        selector: row => row.March,
        sortable: true,
        center: false,
    },
    {
        name: <H4>April</H4>,
        selector: row => row.April,
        sortable: true,
        center: false,
    },
    {
        name: <H4>May</H4>,
        selector: row => row.May,
        sortable: true,
        center: false,
    },
    {
        name: <H4>June</H4>,
        selector: row => row.June,
        sortable: true,
        center: false,
    },
    {
        name: <H4>July</H4>,
        selector: row => row.July,
        sortable: true,
        center: false,
    },
    {
        name: <H4>August</H4>,
        selector: row => row.August,
        sortable: true,
        center: false,
    },
    {
        name: <H4>September</H4>,
        selector: row => row.September,
        sortable: true,
        center: false,
    },
    {
        name: <H4>October</H4>,
        selector: row => row.October,
        sortable: true,
        center: false,
    },
    {
        name: <H4>November</H4>,
        selector: row => row.November,
        sortable: true,
        center: false,
    },
    {
        name: <H4>December</H4>,
        selector: row => row.December,
        sortable: true,
        center: false,
    },
];

export const IncomeVsExpenseColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: false,
        width: '80px'
    },
    {
        name: <H4>Category</H4>,
        selector: row => row.Category,
        sortable: true,
        center: false,
    },

    {
        name: <H4>January</H4>,
        selector: row => row.January,
        sortable: true,
        center: false,
    },
    {
        name: <H4>February</H4>,
        selector: row => row.February,
        sortable: true,
        center: false,
    },
    {
        name: <H4>March</H4>,
        selector: row => row.March,
        sortable: true,
        center: false,
    },
    {
        name: <H4>April</H4>,
        selector: row => row.April,
        sortable: true,
        center: false,
    },
    {
        name: <H4>May</H4>,
        selector: row => row.May,
        sortable: true,
        center: false,
    },
    {
        name: <H4>June</H4>,
        selector: row => row.June,
        sortable: true,
        center: false,
    },
    {
        name: <H4>July</H4>,
        selector: row => row.July,
        sortable: true,
        center: false,
    },
    {
        name: <H4>August</H4>,
        selector: row => row.August,
        sortable: true,
        center: false,
    },
    {
        name: <H4>September</H4>,
        selector: row => row.September,
        sortable: true,
        center: false,
    },
    {
        name: <H4>October</H4>,
        selector: row => row.October,
        sortable: true,
        center: false,
    },
    {
        name: <H4>November</H4>,
        selector: row => row.November,
        sortable: true,
        center: false,
    },
    {
        name: <H4>December</H4>,
        selector: row => row.December,
        sortable: true,
        center: false,
    },
];