import { Item, Quantity, Price, Payment, Itemtotal, DiscountT, TaxPercentage, PaymentHistory, ViewMore, Reciept } from '../../../../Constant';
import { P, Btn } from '../../../../AbstractElements';
import InvoiceHeader from './InvoiceHeader';
import InvoiceDescription from './InvoiceDescription';
import DataTable from 'react-data-table-component';
import { Col, Label, Row, Container, CardBody, Card, Nav, NavItem, NavLink, TabContent, TabPane, } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import InvoiceTotalAmount from '../CheckOut/InvoiceTotal';
import { useSelector } from 'react-redux';
import { PaymntColumns } from '../../../../Data/MockTable/UserTableHeader';
import { conditionalRow } from '../../../Common/Rowstyle/Rowstyle';
import { getClientUrlDataToken } from '../../../../Utils/restUtils';
import { getOrderRecentPayment, getOrderRecentPaymentRole } from '../../../../CommonUrl';
import { useNavigate } from 'react-router-dom';

const ItemDescription = React.forwardRef((props, ref) => {
    const userInvoice = props.invoiceData;
    const style2 = { width: 85, fontSize: 13, padding: 5 };
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [statusColor, setStatusColor] = useState('');
    const [primarycolorTab, setprimarycolorTab] = useState('1');
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const currency_symbol = userInvoice.currency_symbol;

    useEffect(() => {
        getInvoiceData();
        paymentData();
    }, [])


    const getInvoiceData = async () => {
        if (userInvoice.payment_status === "pending") {
            setStatusColor('#dc0808');
        } else if (userInvoice.payment_status === 'paid') {
            setStatusColor('#67c100');
        } else if (userInvoice.payment_status === 'paschal') {
            setStatusColor('#e56809');
        } else {
            setStatusColor('#534686');
        }
    }

    const orderItems = userInvoice.order_item;
    const orderItemTaxes = userInvoice.order_item_tax;

    const itemTaxPercentages = orderItems.map(orderItem => {
        const itemTaxes = orderItemTaxes.filter(tax => tax.item_id === orderItem.item_id);
        const taxPercentage = itemTaxes.reduce((acc, tax) => acc + tax.tax_percentage, 0);
        return { item_id: orderItem.item_id, tax_percentage: taxPercentage };
    });

    const printSection = () => {
        return (
            <div ref={ref}>
                <div className='invoice invoice-print-styling'>
                    <Row>
                        <CardBody  >
                            <div>
                                <InvoiceHeader invoice={userInvoice} />
                                <InvoiceDescription invoice={userInvoice} />
                                <div className=" table-responsive invoice-table " id="table">
                                    <table className='table table-bordered' >
                                        <tbody>
                                            <tr>
                                                <th className="item text-left">
                                                    {Item}
                                                </th>
                                                <th className="Hours  text-end">
                                                    {Quantity}
                                                </th>
                                                <th className="Rate  text-end">
                                                    {Price}
                                                </th>
                                                <th className="Rate  text-end">
                                                    {DiscountT}
                                                </th>
                                                <th className="Rate  text-end">
                                                    {TaxPercentage}
                                                </th>

                                                <th className="subtotal  text-end">
                                                    {Itemtotal}
                                                </th>
                                            </tr>
                                            {userInvoice.order_item?.map((item, index) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td className=' text-left'>
                                                            <Label className=''>{`${item.name}`}</Label>
                                                        </td>
                                                        <td className=' text-end'>
                                                            <P attrPara={{ className: 'itemtext digits' }}>{item.quantity}</P>
                                                        </td>
                                                        <td className='text-end'>
                                                            <P attrPara={{ className: 'itemtext digits' }}>{`${currency_symbol} ${item.unit_price}`}</P>
                                                        </td>
                                                        <td className='text-end'>
                                                            <P attrPara={{ className: 'itemtext digits' }}>{`${currency_symbol} ${item.discount ?? 0}`}</P>
                                                        </td>
                                                        <td className="text-end">
                                                            <P attrPara={{ className: '' }}>{`${itemTaxPercentages[index].tax_percentage}%`}</P>
                                                        </td>
                                                        <td className="text-end">
                                                            <P attrPara={{ className: '' }}>{`${currency_symbol} ${item.sub_total ?? 0}`}</P>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                {/* <PrintInvoice /> */}
                            </div>
                            {Object.keys(userInvoice).length > 0 && (
                                <InvoiceTotalAmount item={userInvoice} />
                            )}
                        </CardBody>
                    </Row >
                </div>
                <div>
                    <div
                        className="feedback-section text-center mt-3 pt-2 pb-2"
                    >
                        For Feedbacks And Complaints Call: <span className='phno'>+{userInvoice.company_country_code + " " + userInvoice.company_phone}</span>
                    </div>
                    <div
                        className="thank-you-section text-center pt-3 pb-3"

                    >
                        Thank you choosing {userInvoice.company_name}
                    </div>
                </div>
            </div>
        )
    }

    const handlePageChange = page => {
        fetchCustomer(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchCustomer(page, newPerPage);
        setPerPage(newPerPage);
    };

    const redirectToReciept = (id) => {
        const toReciept = `${process.env.PUBLIC_URL}/billing/thermal-print?payment_id=${id}`;
        navigate(toReciept);
    };


    const paymentData = async (page, size = perPage) => {
        setLoading(true);
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getOrderRecentPayment + `?order_id=${userInvoice.order_id}`
            : getOrderRecentPaymentRole + `?order_id=${userInvoice.order_id}`);
        if (data.status === true) {
            setSubscriptionList(data.payment.map((e, index) => {
                return {
                    Si: index + 1,
                    Date: <span>{e.date}</span>,
                    TransactionId: e.transaction_id,
                    Amount: <span>{e.amount}</span>,
                    Account: e.account_name,
                    Description: e.payment_description,
                    Action:
                        <div>
                            <span>
                                <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => redirectToReciept(e.payment_id) }}>{Reciept}</Btn>
                            </span>
                        </div >
                }
            }))
            setLoading(false);
        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };

    return (
        <Container>
            <Row>
                <Col sm="12" xl="6 xl-100">
                    <Card>
                        <CardBody>
                            <Nav className="nav-primary" tabs>
                                <NavItem>
                                    <NavLink className={primarycolorTab === '1' ? 'active' : ''} style={{
                                        background: primarycolorTab === '1' ? statusColor : 'transparent',
                                    }}
                                        onClick={() => setprimarycolorTab('1')}>
                                        {userInvoice.payment_status}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={primarycolorTab === '2' ? 'active' : ''} onClick={() => setprimarycolorTab('2')}>
                                        {PaymentHistory}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={primarycolorTab}>
                                <TabPane tabId="1">
                                    {printSection()}
                                </TabPane>
                                <TabPane tabId="2">
                                    <div className="order-history table-responsive" style={{ paddingTop: '30px' }}>
                                        <DataTable
                                            data={subscriptionList}
                                            columns={PaymntColumns}
                                            pagination
                                            progressPending={loading}
                                            paginationServer
                                            defaultSortAsc
                                            paginationTotalRows={totalRows}
                                            paginationDefaultPage={currentPage}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            conditionalRowStyles={conditionalRow}
                                            onChangePage={handlePageChange}

                                            style={{ border: '1px solid' }}
                                        />
                                    </div>
                                    <P attrPara={{ className: 'mb-0 m-t-30' }} >{ }</P>
                                </TabPane>
                            </TabContent>
                        </CardBody>


                    </Card>
                </Col>
            </Row>
        </Container>

    )
});

export default ItemDescription;