import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, H4, Breadcrumbs, Btn } from '../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../Utils/restUtils';
import { Add, Search, Filter, Export, IncomesList, Incomes, Vendor, Header, Status } from '../../Constant';
import { getIncome, deleteIncome, getIncomeRole, selectHeaders, selectHeadersRole, selectVendor, selectVendorRole } from '../../CommonUrl';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import SweetAlert from 'sweetalert2';
import Popup from "../Common/Popup/popup";
import * as XLSX from 'xlsx';
import HeaderAdd from "./IncomePay";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import DeactivateModal from './IncomeDetails';
import { toast } from 'react-toastify';
import Transparentspinner from '../Common/Loader/Transparentspinner';

const IncomeList = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm({});
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const [customerLists, setCustomerList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [filters, setFilters] = useState({
        search: '',
        start: '',
        end: '',
        vendor: '',
        header: '',
        status: ''
    });
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [dateRange, setDateRange] = useState([null, null]);
    const [currentPage, setCurrentPage] = useState(1);
    const [modal, setModal] = useState(false);
    const [startDate, endDate] = dateRange
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const [selectedExpenseId, setSelectedExpenseId] = useState(null);
    const [apiVendor, setapiVendor] = useState([]);
    const [apiHeader, setapiHeader] = useState([]);
    const [deactivateValue, setDeactivateValue] = useState({})
    const [modaldeactivate, setmodalDeactivate] = useState(false);
    const [sortConfig, setSortConfig] = useState({ column: '', order: '' });

    useEffect(() => {
        fetchIncome(1);
        vendorData();
        headerData();
    }, []);

    const statuscheck = [
        { value: "received", name: "Received" },
        { value: "partial", name: "Partially Paid" },
        { value: "pending", name: "Pending" }
    ]


    const changepwdtitile = <span className='billerqColor bolder '>
        <h3>Receive Payment</h3>
    </span>
    const dectivateStbTitle = <span className='billerqColor bolder '>
        <h3>Payment Details</h3>
    </span>

    const ExpensesColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            center: false,
            width: '50px'
        },
        {
            name: <i className="fa fa-gears" style={{ fontSize: '15px' }}></i>,
            selector: row => row.action,
            cell: row => <ActionCell row={row} />,
            center: true,
            width: '70px',

        },
        {
            name: <H4>Vendor</H4>,
            id: 'vendor_name',
            selector: row => row.vendor,
            sortable: true,
            center: false,
            wrap: true,
        },
        {
            name: <H4>Header</H4>,
            id: 'header_name',
            selector: row => row.header,
            sortable: true,
            center: false,
            wrap: true,
        },
        {
            name: <H4>Amount</H4>,
            id: 'amount',
            selector: row => authState.currency + row.amount,
            sortable: true,
            center: false,
            wrap: true,
        },
        {
            name: <H4>Total Paid</H4>,
            id: 'paid_amount',
            selector: row => authState.currency + row.Total_paid,
            sortable: true,
            center: false,
            wrap: true,
        },
        {
            name: <H4>Balance</H4>,
            id: 'balance',
            selector: row => authState.currency + row.Balance,
            sortable: true,
            center: false,
            wrap: true,
        },
        {
            name: <H4>Bill Date</H4>,
            id: 'bill_date',
            selector: row => row.bill_date,
            sortable: true,
            center: false,
            wrap: true,
        },
        {
            name: <H4>Due Date</H4>,
            id: 'due_date',
            selector: row => row.due_date,
            sortable: true,
            center: false,
            wrap: true,
        },
        {
            name: <H4>Status</H4>,
            selector: row => row.PaymentStatus,
            cell: row => (
                <span
                    className={`badge badge-${row.PaymentStatus}`}
                >
                    {row.PaymentStatus}
                </span>
            ),
            width: '95px',
        },
    ];

    const handleSort = (column) => {
        let newOrder = 'asc';
        if (sortConfig.column === column.id) {
            newOrder = sortConfig.order === 'asc' ? 'desc' : 'asc';
        }
        setSortConfig({ column: column.id, order: newOrder });
        fetchIncome(currentPage, perPage, filters.search, filters.start, filters.end, filters.vendor, filters.header, filters.status, column.id, newOrder);
    };

    const handleApiError = (status) => {
        if (status >= 400 && status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (status >= 500 && status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const handleModal = (id) => {
        setSelectedExpenseId(id);
        setModal(!modal);
    };

    const ActionCell = ({ row }) => {
        const [dropdownOpen, setDropdownOpen] = React.useState(false);
        const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

        const handleEditClick = () => {
            const editFormURL = `${process.env.PUBLIC_URL}/Expenses/Income-edit?id=${row.id}`;
            navigate(editFormURL);
        };

        const deletePackageList = () => {
            SweetAlert.fire({
                title: 'Confirm deletion?',
                text: 'This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'cancel',
                reverseButtons: true
            }).then(async (result) => {
                setLoading(true);
                if (result.value) {
                    const deleteRes = { income_id: row.id }
                    const packageDelete = await postClientUrlWithToken(authState.apiUrl, deleteIncome, deleteRes);
                    if (packageDelete.status === 200) {
                        if (packageDelete.data.status === true) {
                            SweetAlert.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            );
                            fetchIncome(currentPage, perPage, filters.search, filters.start, filters.end, filters.vendor, filters.header, filters.status, sortConfig.column, sortConfig.order);
                            setLoading(false);
                        }
                        else {
                            toast.error(packageDelete.data.message);
                        }
                    } else {
                        handleApiError(packageDelete.status);
                    }
                }
                setLoading(false);
            });
        };

        return (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle color="#00000" style={{ fontSize: '14px', zIndex: 1000 }}>
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu container="body">
                    {row.PaymentStatus === "received" && (
                        <DropdownItem style={toggleStyle} onClick={() => handleModalDeactivate(row)}>Show</DropdownItem>
                    )}
                    {row.PaymentStatus === "partial" && (
                        <>
                            <DropdownItem style={toggleStyle} onClick={() => handleModalDeactivate(row)}>Show</DropdownItem>
                            {(authState.userRole === 1 || authState.permissions.includes('payment-income')) &&
                                <DropdownItem style={toggleStyle} onClick={() => handleModal(row.id)}>Add Payment</DropdownItem>
                            }
                        </>
                    )}
                    {row.PaymentStatus === "pending" && (
                        <>
                            <DropdownItem style={toggleStyle} onClick={() => handleModalDeactivate(row)}>Show</DropdownItem>
                            {(authState.userRole === 1 || authState.permissions.includes('payment-income')) &&
                                <DropdownItem style={toggleStyle} onClick={() => handleModal(row)}>Add Payment</DropdownItem>
                            }
                            {(authState.userRole === 1 || authState.permissions.includes('edit-income')) &&
                                <DropdownItem style={toggleStyle} onClick={handleEditClick}>Edit</DropdownItem>
                            }
                        </>
                    )}
                    {row.PaymentStatus !== "received" && row.PaymentStatus !== "partial" && (authState.userRole === 1 || authState.permissions.includes('delete-income')) && (
                        <DropdownItem style={{ padding: '10px 20px', color: 'red', opacity: '0.8', fontSize: '14px' }} onClick={() => deletePackageList(row.id)}>Delete</DropdownItem>
                    )}
                </DropdownMenu>
            </Dropdown>
        );
    };


    const handleModalDeactivate = (e) => {
        setDeactivateValue(e);
        setmodalDeactivate(!modaldeactivate);
    }

    const fetchIncome = async (page, size = perPage, search = '', startDate = '', endDate = '', vendorId = '', headerId = '', status = '', sortBy = '', sortOrder = '') => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ?
            getIncome + `?page=${page}&page_length=${size}&search=${search}&start_date=${startDate}&end_date=${endDate}&vendor_id=${vendorId}&header_id=${headerId}&status=${status}&sort_by=${sortBy}&sort_order=${sortOrder}` :
            getIncomeRole + `?page=${page}&page_length=${size}&search=${search}&vendor_id=${vendorId}&header_id=${headerId}&status=${status}&sort_by=${sortBy}&sort_order=${sortOrder}`);
        if (response.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            const valuePackages = response.data.data
            setCustomerList(Object.keys(valuePackages).map((e, index) => {
                return {
                    Si: index + 1,
                    id: valuePackages[e].id,
                    vendor: valuePackages[e].vendor_name,
                    header: valuePackages[e].header_name,
                    amount: valuePackages[e].amount.toString(),
                    account: valuePackages[e].account_name,
                    method: valuePackages[e].payment_method_name,
                    PaymentStatus: valuePackages[e].payment_status,
                    bill_date: valuePackages[e].bill_date,
                    due_date: valuePackages[e].due_date,
                    Balance: valuePackages[e].balance,
                    Total_paid: valuePackages[e].paid_amount,
                }
            }
            ))
            setTotalRows(response.data.total);
            setLoading(false);

        } else {
            handleApiError(response.status);
        }
    };

    const exportPackageSummaryExcel = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getIncome +
            `?export=income_data &search=${filters?.search ?? ''}&vendor_id=${filters?.vendor ?? ''}
            &start_date=${filters?.start ?? ''}&end_date=${filters?.end ?? ''}&header_id=${filters?.header ?? ''}
            &status=${filters.status}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}` :
            getIncomeRole + `?export=income_data&search=${filters?.search ?? ''}&vendor_id=${filters?.vendor ?? ''}
            &start_date=${filters?.start ?? ''}&end_date=${filters?.end ?? ''}&header_id=${filters?.header ?? ''}
            &status=${filters.status}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`);
        const dataToExport = response.data.map(({ vendor_name, header_name, amount, bill_date, due_date, payment_status }) => ({ Vendor: vendor_name, Header: header_name, Amount: authState.currency + amount, Bill_dat: bill_date, Due_date: due_date, Status: payment_status }));
        if (response.status === true) {
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Income Data');
            XLSX.writeFile(wb, 'income.xlsx');
        } else {
            handleApiError(response.status);
        }
        setLoading(false);
    };

    const exportPackageSummaryPdf = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getIncome +
            `?export=income_data&search=${filters?.search ?? ''}&vendor_id=${filters?.vendor ?? ''}
            &start_date=${filters?.start ?? ''}&end_date=${filters?.end ?? ''}&header_id=${filters?.header ?? ''}
            &status=${filters.status}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}` :
            getIncomeRole + `?export=expense_data&search=${filters?.search ?? ''}&vendor_id=${filters?.vendor ?? ''}
            &start_date=${filters?.start ?? ''}&end_date=${filters?.end ?? ''}&header_id=${filters?.header ?? ''}
            &status=${filters.status}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`);
        const dataToExport = response.data.map(({ vendor_name, header_name, amount, bill_date, due_date, payment_status }, index) => ({ Sl: index + 1, vendor_name, header_name, amount, bill_date, due_date, payment_status }));
        if (response.status === true) {
            const doc = new jsPDF();
            const columns = ["Sl", "Vendor", "Header", "Amount", "Bill Date", "Due Date", "Status"];
            const rows = dataToExport.map(({ vendor_name, header_name, amount, bill_date, due_date, payment_status }, index) => [index + 1, vendor_name, header_name, amount, bill_date, due_date, payment_status]);
            doc.autoTable({
                head: [columns],
                body: rows
            });
            doc.save("income_data.pdf");
        } else {
            handleApiError(response.status);
        }
        setLoading(false);
    };

    const vendorData = async () => {
        setLoading(true);
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? selectVendor : selectVendorRole);
        if (data.status === true) {
            setapiVendor(data.data);
        } else {
            handleApiError(data.status);
        }
        setLoading(false);
    };

    const headerData = async () => {
        setLoading(true);
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? selectHeaders : selectHeadersRole);
        if (data.status === true) {
            setapiHeader(data.data);
        } else {
            handleApiError(data.status);
        }
        setLoading(false);
    };


    const handlePageChange = page => {
        fetchIncome(page, perPage, filters.search, filters.start, filters.end, filters.vendor, filters.header, filters.status, sortConfig.column, sortConfig.order);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchIncome(page, newPerPage);
        fetchIncome(page, newPerPage, filters.search, filters.start, filters.end, filters.vendor, filters.header, filters.status, sortConfig.column, sortConfig.order);
        setPerPage(newPerPage);
    };
    const onSubmit = async (data) => {
        const search = data.search;
        const vendor = data.vendor;
        const header = data.header;
        const status = data.status;
        const start = dateRange[0] ? moment(dateRange[0]).format("DD-MM-YYYY") : '';
        const end = dateRange[1] ? moment(dateRange[1]).format("DD-MM-YYYY") : '';
        setFilters({
            search,
            start,
            end,
            vendor,
            header,
            status
        });
        fetchIncome(1, perPage, search, start, end, vendor, header, status);
    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={Incomes} parent="Expenses" title={Incomes} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -25px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{IncomesList}</H3>
                    <div >
                        <CardBody className="dropdown-basic">
                            {(authState.userRole === 1 || authState.permissions.includes('store-income')) &&
                                <Link to={`${process.env.PUBLIC_URL}/Expenses/Income-add`} className="btn btn-primary me-2" >{Add}</Link>
                            }
                            {(authState.userRole === 1 || authState.permissions.includes('export-income')) &&
                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                    <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                        {Export} <i className="icofont icofont-arrow-up"></i>
                                    </DropdownToggle>
                                    <DropdownMenu container="body">
                                        <DropdownItem style={toggleStyle} onClick={() => exportPackageSummaryExcel()} >Excel</DropdownItem>
                                        <DropdownItem style={toggleStyle} onClick={() => exportPackageSummaryPdf()}>Pdf</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            }
                        </CardBody>
                    </div>
                </CardHeader>

                <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Row className="ps-3 gap-sm-3 gap-md-2 filterGap">
                        <Col sm="12" md='5' lg='2'>
                            <input className="form-control" placeholder={Search} id="validationCustom01" type="text"
                                {...register('search')} />
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <div style={{ position: 'relative', zIndex: 2 }}>
                                <DatePicker
                                    className="form-control digits w-100"
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    isClearable={true}
                                    monthsShown={2}
                                    popperPlacement="bottom"
                                    calendarClassName='parallel-calendar'
                                    placeholderText='Select Date Range'

                                />
                            </div>
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <select className="form-select"  {...register('vendor')}>
                                <option key='vendor' value="">{Vendor}</option>
                                {apiVendor.map((values, index) => (
                                    <option key={values.id} value={values.id}>{values.title}</option>
                                ))}
                            </select>
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <select className="form-select"  {...register('header')}>
                                <option key='header' value="">{Header}</option>
                                {apiHeader.map((values, index) => (
                                    <option key={values.id} value={values.id}>{values.title}</option>
                                ))}
                            </select>
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <select className="form-select"   {...register('status')}>
                                <option value="">{Status}</option>
                                {statuscheck.map((c, index) => (
                                    <option key={index} value={c.value}>{c.name}</option>

                                ))}
                            </select>
                        </Col>


                        <Col sm="12" md="5" lg='1' className="custom-filtrs">
                            <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                        </Col>
                    </Row>
                </Form>
                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={ExpensesColumns}
                            data={customerLists}
                            onSort={handleSort} // Attach sorting handler here
                            sortServer // Enable server-side sorting
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
            <Popup isOpen={modaldeactivate} title={dectivateStbTitle} toggler={handleModalDeactivate}>
                <DeactivateModal
                    deactivateValue={deactivateValue}
                    stbLists={fetchIncome}
                    handleModalDeactivate={handleModalDeactivate}
                    currentPageNo={currentPage}
                    perPagelist={perPage}
                />
            </Popup>

            <Popup isOpen={modal} title={changepwdtitile} toggler={handleModal} >
                <HeaderAdd
                    expenseId={selectedExpenseId}
                />
            </Popup>
        </Fragment>
    );
};

export default IncomeList;