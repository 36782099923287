import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import { Address, Title, Vendor, EditVendor, Email, MobileNo, Save, TaxIn } from "../../Constant";
import { updateVendor, editVendorRole, editVendor, updateVendorRole } from "../../CommonUrl";
import { useSelector } from "react-redux";
import { getClientUrlDataToken, postClientUrlWithToken } from "../../Utils/restUtils";
import { toast } from 'react-toastify';
import Transparentspinner from "../Common/Loader/Transparentspinner";

const VendorEdit = () => {
  const { register, handleSubmit, control, formState: { errors }, setValue } = useForm();
  const location = useLocation();
  const authState = useSelector((state) => state.auth);
  const searchParams = location.search;
  const params = new URLSearchParams(searchParams);
  const param1 = params.get("id");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const form = new FormData();

  useEffect(() => {
    categoryData();
  }, []);

  const categoryData = async () => {
    setLoading(true);
    let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? editVendor + `?vendor_id=${param1}` : editVendorRole + `?vendor_id=${param1}`);
    if (data.status === true) {
      const single_Api = data.data;
      setValue('vendor_id', single_Api.id);
      setValue('title', single_Api.title);
      setValue('tax_in', single_Api.taxIn ?? '');
      const phoneNumber = single_Api.country_code !== null ? single_Api.country_code + single_Api.mobile : '';
      setValue('mobile', phoneNumber);
      setValue('countryCode', single_Api.country_code !== null ? single_Api.country_code : '');
      setValue('email', single_Api.email ?? '');
      setValue('address', single_Api.address && single_Api.address !== 'null' ? single_Api.address : '');
    } else if (data.status >= 400 && data.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (data.status >= 500 && data.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    } else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }
    setLoading(false);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const countryCode = data.countryCode || '';
    const phoneNumber = data.mobile || '';
    const phoneNumberWithoutCountryCode = phoneNumber.startsWith(countryCode) ? phoneNumber.slice(countryCode.length) : phoneNumber;

    form.append("vendor_id", param1);
    form.append("title", data.title);
    form.append("tax_in", data.tax_in);
    form.append("country_code", countryCode);
    form.append("mobile", phoneNumberWithoutCountryCode);
    form.append("email", data?.email ?? '');
    form.append("address", data.address);

    const editVendorResponse = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? updateVendor : updateVendorRole, form, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });

    if (editVendorResponse.status === 200) {
      if (editVendorResponse.data.status === true) {
        toast.success(editVendorResponse.data.message);
        navigate(`${process.env.PUBLIC_URL}/Expenses/Vendor`);
      } else {
        toast.error(editVendorResponse.data.message);
        setLoading(false);
      }
    } else if (editVendorResponse.status >= 400 && editVendorResponse.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (editVendorResponse.status >= 500 && editVendorResponse.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    } else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }
    setLoading(false);
  };

  return (
    <Fragment>
      {loading && <Transparentspinner />}

      <Breadcrumbs mainTitle={Vendor} parent="Settings" title={Vendor} />
      <Card>
        <CardHeader className="pb-0">
          <H3>{EditVendor}</H3>
        </CardHeader>
        <CardBody>
          <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="orderNo">
                  {Title} <span className="requireStar">*</span>
                </Label>
                <input
                  name="title"
                  className="form-control"
                  type="text"
                  {...register("title")}
                />
              </Col>
              <Col md="6">
                <Label className="form-label" for="orderNo">
                  {TaxIn}
                </Label>
                <input
                  name="tax_in"
                  className="form-control"
                  type="text"
                  {...register("tax_in")}
                />
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" >
                  {MobileNo}
                </Label>
                <Controller
                  name="mobile"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      country={"in"}
                      enableSearch={true}
                      onChange={(value, country) => {
                        setValue("countryCode", country.dialCode);
                        field.onChange(value);
                      }}
                      inputStyle={{
                        width: "100%",
                        height: "46px",
                        padding: "10px",
                        paddingLeft: "50px",
                        fontSize: "16px",
                        borderRadius: "8px",
                        borderColor: "rgba(83, 70, 134, 0.1019607843)",
                      }}
                      searchClass="none"
                    />
                  )}
                />
                <span className="text-danger">
                  {errors.phonenumber && errors.phonenumber.message}
                </span>
              </Col>
              <Col md="6">
                <Label className="form-label" >
                  {Email}
                </Label>
                <input
                  className="form-control"
                  id="validationCustom01"
                  type="email"
                  {...register("email")}
                />
              </Col>
              <Col>
                <Label className="form-label">{Address} </Label>
                <textarea className="form-control"
                  {...register("address")}
                />
              </Col>
            </Row>
            <FormGroup></FormGroup>
            <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default VendorEdit;
