import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Row, Col } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, Breadcrumbs, Btn } from '../../AbstractElements';
import { getClientUrlDataToken } from '../../Utils/restUtils';
import { AddonSummaryRep, AddonSummaryList, Export, Search, Filter } from '../../Constant';
import { useLocation, useNavigate } from 'react-router-dom';
import { viewAddonReport, viewAddonReportRole } from '../../CommonUrl';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { AddonSummaryColumns } from '../../Data/MockTable/ReportTableHeader';
import { EarningsSvg } from '../../Data/Widgets/General/Data';
import Progress from '../Common/Progress';
import DatePicker from 'react-datepicker';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import Transparentspinner from '../Common/Loader/Transparentspinner';

const AddonSummary = () => {
    const { register, handleSubmit } = useForm({});
    const authState = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const sortedBy = params.get("sortBy") || '';
    const sortedOrder = params.get("sortOrder") || '';
    const searchKey = params.get('search') || '';
    const start_date = params.get('start_date') || '';
    const end_date = params.get('end_date') || '';
    const [customerLists, setCustomerList] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [sortConfig, setSortConfig] = useState({ column: sortedBy, order: sortedOrder });
    const [filters, setFilters] = useState({
        searchKey: searchKey,
        start_date: start_date,
        end_date: end_date,
    });

    const [StaticWidgetsData, setStaticWidgetsData] = useState([
        {
            id: 1,
            title: 'Paid',
            counter: "0",
            svg: <EarningsSvg />,
            class: 'progress-gradient-secondary',
            progress: '75%'
        },
    ])

    useEffect(() => {
        fetchAddonSummary(1);
    }, []);

    const handleSort = (column) => {
        let newOrder = 'asc';
        if (sortedBy === column.id) {
            newOrder = sortedOrder === 'asc' ? 'desc' : 'asc';
            setSortConfig({ column: column.id, order: newOrder });
            navigate(`/report/addon-summary?search=${filters.searchKey}&start_date=${filters.start_date}&end_date=${filters.end_date}&sortBy=${column.id}&sortOrder=${newOrder}`);
        } else {
            newOrder = sortedOrder === 'asc' ? 'desc' : 'asc';
            setSortConfig({ column: column.id, order: newOrder });
            navigate(`/report/addon-summary?search=${filters.searchKey}&start_date=${filters.start_date}&end_date=${filters.end_date}&sortBy=${column.id}&sortOrder=${newOrder}`);
        }
    };

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleApiError = (status) => {
        if (status >= 400 && status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (status >= 500 && status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const handlePageChange = page => {
        fetchAddonSummary(page, perPage, filters.searchKey, filters.start_date, filters.end_date, sortConfig.column, sortConfig.order);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchAddonSummary(page, newPerPage, filters.searchKey, filters.start_date, filters.end_date, sortConfig.column, sortConfig.order);
        setPerPage(newPerPage);
    };
    
    const onSubmit = async (data) => {
        const search = data.search;
        const start_date = startDate ? moment(startDate).format("DD-MM-YYYY") : '';
        const end_date = endDate ? moment(endDate).format("DD-MM-YYYY") : '';
        const queryParams = new URLSearchParams({
            search: search,
            start_date,
            end_date,
            sortBy: sortConfig.column || '',
            sortOrder: sortConfig.order || '',
        }).toString();
        navigate(`/report/addon-summary?${queryParams}`);
        setFilters({
            search,
            start_date,
            end_date,
        });
        fetchAddonSummary(currentPage, perPage, search, start_date, end_date);
    };

    const fetchAddonSummary = async (page, size = perPage) => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ?
            viewAddonReport + `?page=${page}&page_length=${size}&search=${filters.searchKey}&start_date=${filters.start_date}&end_date=${filters.end_date}&sort_by=${sortConfig.column ? sortConfig.column : ''}&sort_order=${sortConfig.order ? sortConfig.order : ''}` :
            viewAddonReportRole + `?page=${page}&page_length=${size}&search=${filters.searchKey}&start_date=${filters.start_date}&end_date=${filters.end_date}&sort_by=${sortConfig.column ? sortConfig.column : ''}&sort_order=${sortConfig.order ? sortConfig.order : ''}`);
        if (response.status === true) {
            const totalAmount = response.data.total_amount.replace(',', '');
            setStaticWidgetsData(prevData => [
                {
                    ...prevData[0],
                    counter: totalAmount
                }
            ]);
            setResetPaginationToggle(!resetPaginationToggle);
            setLoading(true);
            const valuePackages = response.data.add_on.data
            const SerialNo = (page - 1) * size + 1;
            setCustomerList(valuePackages.map((e, index) => {
                return {
                    Si: SerialNo + index,
                    Packages: e.add_on_name,
                    unitPrice: authState.currency + e.unit_price,
                    unitTax: authState.currency + e.unit_tax,
                    totalPrice: authState.currency + e.total_price,
                    totalTax: authState.currency + e.total_tax,
                    grandTotal: <span style={{ fontWeight: 'bold', color: '#534686' }}>{authState.currency}{e.grand_total}</span>,
                    invoiceNo: e.invoice_count,
                }
            }
            ))
            setTotalRows(response.data.add_on.total);
        } else {
            handleApiError(response.status)
        }
        setLoading(false);
    };

    const exportAddonSummaryExcel = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewAddonReport +
            `?export=addon_summary_report&search=${filters?.searchKey ? filters.searchKey : ''}&start_date=${filters?.start_date ?? ''}&end_date=${filters?.end_date ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}` :
            viewAddonReportRole + `?export=addon_summary_report&search=${filters?.searchKey ? filters.searchKey : ''}&start_date=${filters?.start_date ?? ''}&end_date=${filters?.end_date ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`);
        const dataToExport = response.data.add_on.map(({ add_on_name, unit_price, unit_tax, total_price, total_tax, grand_total, invoice_count }) => ({ Package: add_on_name, Unit_Price: authState.currency + unit_price, Unit_Tax: authState.currency + unit_tax, Total_Price: authState.currency + total_price, Total_Tax: authState.currency + total_tax, Grand_Total: authState.currency + grand_total, No_of_invoice: invoice_count }));
        if (response.status === true) {
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Addon Summary Data');
            XLSX.writeFile(wb, 'addon_summary_report.xlsx');
        } else {
            handleApiError(response.status)
        }
        setLoading(false);
    };

    const exportAddonSummaryPdf = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewAddonReport +
            `?export=addon_summary_report&search=${filters?.searchKey ? filters.searchKey : ''}&start_date=${filters?.start_date ?? ''}&end_date=${filters?.end_date ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}` :
            viewAddonReportRole + `?export=addon_summary_report&search=${filters?.searchKey ? filters.searchKey : ''}&start_date=${filters?.start_date ?? ''}&end_date=${filters?.end_date ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`);
        const dataToExport = response.data.add_on.map(({ add_on_name, unit_price, unit_tax, total_price, total_tax, grand_total, invoice_count }) => ({ add_on_name, unit_price, unit_tax, total_price, total_tax, grand_total, invoice_count }));
        if (response.status === true) {
            const doc = new jsPDF();
            const columns = ["Sl", "Package", "Unit Price", "Unit Tax", "Total Price", "Total Tax", "Grand Total", "Number of invoice",];
            const rows = dataToExport.map(({ add_on_name, unit_price, unit_tax, total_price, total_tax, grand_total, invoice_count }, index) => [index + 1, add_on_name, unit_price, unit_tax, total_price, total_tax, grand_total, invoice_count]);
            doc.autoTable({
                head: [columns],
                body: rows
            });
            doc.save("Addon_ report_data.pdf");
        } else {
            handleApiError(response.status)
        }
        setLoading(false);
    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={AddonSummaryRep} parent="Reports" title={AddonSummaryRep} />
            <Progress details={StaticWidgetsData} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -25px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{AddonSummaryList}</H3>
                    <div>
                        {(authState.userRole === 1 || authState.permissions.includes('export-report')) &&
                            <CardBody className="dropdown-basic">
                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                    <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                        {Export} <i className="icofont icofont-arrow-up"></i>
                                    </DropdownToggle>
                                    <DropdownMenu container="body">
                                        <DropdownItem style={toggleStyle} onClick={() => exportAddonSummaryExcel()} ><i className="fa fa-file-excel-o me-2"></i>Excel</DropdownItem>
                                        <DropdownItem style={toggleStyle} onClick={() => exportAddonSummaryPdf()}><i className="fa fa-file-pdf-o me-2"></i>Pdf</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </CardBody>
                        }
                    </div>
                </CardHeader>
                <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Row className="ps-3 gap-sm-3 gap-md-2 filterGap">
                        <Col sm="12" md='5' lg='2'>
                            <input className="form-control" placeholder={Search} type="text"
                                {...register('search')} />
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <div style={{ position: 'relative', zIndex: 2 }}>
                                <DatePicker
                                    className="form-control digits w-100"
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    isClearable={true}
                                    monthsShown={2}
                                    popperPlacement="bottom"
                                    calendarClassName='parallel-calendar'
                                    placeholderText='Select Date Range'
                                />
                            </div>
                        </Col>
                        <Col sm="12" md="5" lg='1' className="custom-filtrs">
                            <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                        </Col>
                    </Row>
                </Form>

                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={AddonSummaryColumns}
                            data={customerLists}
                            onSort={handleSort} // Attach sorting handler here
                            sortServer // Enable server-side sorting
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default AddonSummary;