import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import QRCode from "react-qr-code";
import { getCompany } from '../../../../CommonUrl';
import { useSelector } from 'react-redux';
import { postClientUrlWithToken } from '../../../../Utils/restUtils';


const SubCard = React.forwardRef((props, ref) => {
    const authState = useSelector((state) => state.auth);
    const [active, setActive] = useState('');
    const [logoUrl, setLogoUrl] = useState('');
    const customer1 = {
        customerId: props.details.customer_id,
        lcoNo: props.details.customer_details.lco_no,
        name: props.details.customer_name,
        address: props.details.customer_details.address,
        joinedDate: props.details.join_date,
        qrCode: '/path-to-qr-code.png',
        connections: props.details.customer_details.connections,
    };

    useEffect(() => {
        companyData();
    }, []);

    const companyData = async () => {
        const data = await postClientUrlWithToken(authState.apiUrl, getCompany);
        if (data.status === 200) {
            const companyData = data.data.data;
            if (companyData.length > 0) {
                const activeCompany = companyData.find(item => item.status === 'active');
                if (activeCompany) {
                    setActive(activeCompany.name);
                    setLogoUrl(activeCompany.company_logo_url);
                }
            } else {
                setLogoUrl("");
            }
        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    return (
        <>
            <div className='row' ref={ref}>
                <Col md={6} sm={12}>
                    <div className='sub-card'>
                        {/* <Col md={12} xs={12}> */}
                        <div className='sub-card-left'>
                            <div className="card-header p-0 m-0  ">
                                {logoUrl !== null &&
                                    <img src={logoUrl} alt="logo" className="logo" />
                                }
                                <span>{active}</span>
                            </div>
                            <div className='d-flex '>
                                <div className="card-body p-0 m-0 ">
                                    <span className='text'>Customer ID</span>
                                    <h4>{customer1.customerId}</h4>
                                    <span className='text'>LCO No</span>
                                    <h4>{customer1.lcoNo}</h4>
                                </div>
                                <div className="qr-code">
                                    <QRCode size={90}
                                        value={`Billerq@${props.details.customer_details.subscriber_id}@${props.details.customer_id}`}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* </Col> */}
                    </div>
                </Col>
                <Col md={6} sm={12} >
                    <div className='sub-card'>
                        {/* <Col md={12} xs={12}> */}
                        <div className='sub-card-right d-flex'>
                            <div className="card-body p-0 m-0 ">
                                <span className='text'>Customer Name</span>
                                <h4>{customer1.name}</h4>
                                <span className='text'>Address</span>
                                <h4>{customer1.address}</h4>
                                <span className='text'>Joined Date</span>
                                <h4>{customer1.joinedDate}</h4>
                                <span className='text'>Service Type</span>
                                <h4>{Array.isArray(customer1.connections) && customer1.connections.length > 0
                                    ? customer1.connections.join(', ')
                                    : 'No connections available'}</h4>
                                <div>
                                    <img src="/path-to-logo.png" alt="" className="logo" />
                                </div>
                            </div>
                        </div>
                        {/* </Col> */}
                    </div >
                </Col>
            </div>
        </>
    )
});

export default SubCard