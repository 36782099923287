import React, { useEffect, useState } from 'react';
import { getClientUrlDataToken } from '../../../Utils/restUtils';
import { getCustomers, getCustomersRole } from '../../../CommonUrl';
import { useNavigate } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Col, Label } from 'reactstrap';
import Typeahead from '../../../CommonElements/TypeAhead';
import { Customer } from '../../../Constant';

const CustomerDropdown = ({ control, errors, onCustomerChange }) => {
  const [customer, setCustomer] = useState([]);
  const [customerOption, setCustomerOption] = useState([]);
  const [filteredCustomerOptions, setFilteredCustomerOptions] = useState([]);
  const authState = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    customerData();
  }, []);

  useEffect(() => {
    const options = customer.map((item) => ({
      value: item.id,
      label: item.name,
      searchFields: `${item.name} ${item.area_name} ${item.mobile} ${item.address}`,
    }));
    setCustomerOption(options);
    setFilteredCustomerOptions(options); // Initialize filtered options
  }, [customer]);

  const customerData = async () => {
    const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCustomers : getCustomersRole);
    if (data.status === true) {
      setCustomer(data.data);
    } else if (data.status >= 400 && data.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (data.status >= 500 && data.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    } else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }
  };

  const handleSearch = (query) => {
    const filteredOptions = customerOption.filter((option) =>
      option.searchFields.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredCustomerOptions(filteredOptions);
  };

  return (
    <Col md="6">
      <Label className="form-label" >{Customer} <span className="requireStar">*</span></Label>
      <Controller
        name="customer"
        control={control}
        render={({ field }) => (
          <Typeahead
            options={filteredCustomerOptions} // Use filtered options
            onInputChange={handleSearch} // Handle input change for filtering
            onChange={(value) => {
              field.onChange(value);
              if (onCustomerChange) onCustomerChange(value);
            }}
            value={field.value}
          />
        )}
      />
      <p className="text-danger">{errors.customer?.message}</p>
    </Col>
  );
};

export default CustomerDropdown;
