import React, { Fragment, useState, useEffect } from 'react';
import { Col, Card, CardHeader, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Media, Label, Input, Form, Row } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import DataTable from 'react-data-table-component';
import { H3, H4, Btn, Breadcrumbs } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../Utils/restUtils';
import { Add, TaxClassList, TaxClass, TaxRate, Search, Filter } from '../../../Constant';
import SearchList from '../../../CommonElements/Search';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { deleteOfflinePayment, viewTax, viewTaxGroup, taxGroupStatus, taxRateremove } from '../../../CommonUrl';
import { TaxColumns, TaxGroupColumns } from '../../../Data/MockTable/SettingsTableHeader';
import Popup from '../../Common/Popup/popup';
import { toast } from 'react-toastify';
import Transparentspinner from '../../Common/Loader/Transparentspinner';

const TaxRatesContainer = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm({});
    const style2 = { width: 85, fontSize: 13, padding: 3, marginRight: '2px' };
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' };
    const toggleStyleDelete = { padding: '10px 20px', color: 'red', opacity: '0.8', fontSize: '14px' };
    const authState = useSelector((state) => state.auth);
    const [taxGroupList, setTaxGroupList] = useState([]);
    const [taxGroupDataList, setTaxGroupListData] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const navigate = useNavigate();
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [taxList, setTaxList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modal, setmodal] = useState(false);

    useEffect(() => {
        if (searchKeyword == '') {
            TaxGroupData();
            TaxData()
        }
    }, [searchKeyword]);

    const filteredItems = taxGroupDataList.filter(
        (item) => item.name && item.name.toLowerCase().includes(searchKeyword.toLowerCase())
    );

    const TaxGroupColumns = [
        {
            name: <i className="fa fa-gears" style={{ fontSize: '20px' }}></i>,
            selector: row => row.action,
            cell: row => <ActionCell row={row} />,
            center: true,
            width: '190px',
        },
        {
            name: <H4>Title</H4>,
            selector: row => row.Name,
            sortable: true,
            width: '120px',
            sortFunction: (a, b) => {
                const nameA = a?.Name?.toString().toLowerCase() || '';
                const nameB = b?.Name?.toString().toLowerCase() || '';
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
            }
        },
        {
            name: <H4>Tax</H4>,
            selector: row => row.Tax,
            sortable: true,
            center: true,
            width: '230px',
        },
        {
            name: <H4>Description</H4>,
            selector: row => row.Description,
            sortable: true,
            width: '190px',
            sortFunction: (a, b) => {
                const nameA = a?.Description?.toString().toLowerCase() || '';
                const nameB = b?.Description?.toString().toLowerCase() || '';
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
            }
        },
        {
            name: <H4>Total %</H4>,
            selector: row => row.Total,
            sortable: true,
            width: '110px',
        },
        {
            name: <H4>Status</H4>,
            selector: row => row.Status,
            center: true,
            width: '190px',
        },
    ];

    const handleSearchInputChange = (event) => {
        setSearchKeyword(event.target.value);
    };

    const handleFilterSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        TaxGroupData();
    };

    const ActionCell = ({ row }) => {
        const [dropdownOpen, setDropdownOpen] = useState(false);
        const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
        const redirectToEditForm = (taxGroupId) => {
            const editFormURL = `${process.env.PUBLIC_URL}/settings/tax-group-edit?id=${taxGroupId}`;
            navigate(editFormURL);
        };

        const deleteTaxGroupList = () => {
            SweetAlert.fire({
                title: 'Confirm deletion?',
                text: 'This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'cancel',
                reverseButtons: true
            }).then(async (result) => {
                setLoading(true);
                if (result.value) {
                    const deleteRes = { tax_group_id: row.tax_group_id }
                    let areaDelete = await postClientUrlWithToken(authState.apiUrl, deleteOfflinePayment, deleteRes);
                    if (areaDelete.status === 200) {
                        if (areaDelete.data.message === "success") {
                            SweetAlert.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            );
                            TaxGroupData();
                        } else {
                            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                        }
                    }
                } else if (areaDelete.status >= 400 && areaDelete.status <= 405) {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
                } else if (areaDelete.status >= 500 && areaDelete.status <= 505) {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                } else {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
                }
                setLoading(false);
            });
        };

        return (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle color="#00000" style={{ padding: '6px 12px', fontSize: '14px', zIndex: 1000 }}>
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu container="body">
                    <DropdownItem style={toggleStyle} onClick={() => redirectToEditForm(row.tax_group_id)}>Edit</DropdownItem>
                    <DropdownItem style={toggleStyle} onClick={() => handleModal(TaxData(row.tax_group_id))}>View tax rate</DropdownItem>
                    {/* <DropdownItem style={toggleStyleDelete} onClick={() => deleteTaxGroupList()}>Delete</DropdownItem> */}
                </DropdownMenu>
            </Dropdown>
        );
    };
    const TaxColumns = [
        {
            name: <H4>Name</H4>,
            selector: row => row.Name,
            sortable: true,
            center: true,
            sortFunction: (a, b) => {
                const nameA = a?.Name?.toString().toLowerCase() || '';
                const nameB = b?.Name?.toString().toLowerCase() || '';
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
            }
        },
        {
            name: <H4>Tax Percentage</H4>,
            selector: row => row.TaxPer,
            sortable: true,
            center: true,
        },
        {
            name: <H4>Sort order</H4>,
            selector: row => row.Sort_order,
            sortable: true,
        },
        {
            name: <H4>Description</H4>,
            selector: row => row.Description,
            sortable: true,
            center: true,
            sortFunction: (a, b) => {
                const nameA = a?.Description?.toString().toLowerCase() || '';
                const nameB = b?.Description?.toString().toLowerCase() || '';
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
            }
        },
        {
            name: <H4>Action</H4>,
            cell: row => <ActionCelltax row={row} />,
            center: true,
        },
    ];

    const ActionCelltax = ({ row }) => {
        const [dropdownOpentax, setDropdownOpentax] = useState(false);
        const toggleDropdowntax = () => setDropdownOpentax(!dropdownOpentax);
        const handlerateEditForm = (row) => {
            const editFormURL = `${process.env.PUBLIC_URL}/settings/tax-rate-edit?id=${row.tax_id}&tax_group_id=${row.tax_group_id}`;
            navigate(editFormURL);
        };

        const deleteTax = () => {
            SweetAlert.fire({
                title: 'Confirm deletion?',
                text: 'This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'cancel',
                reverseButtons: true,
                onClose: () => { }
            }).then(async (result) => {
                setLoading(true);
                if (result.value) {
                    const deleteRes = { tax_id: row.tax_id }
                    const areaDelete = await postClientUrlWithToken(authState.apiUrl, taxRateremove, deleteRes);
                    if (areaDelete.status === 200) {

                        if (areaDelete.data.message === "success") {
                            SweetAlert.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success',
                            );
                            handleModal()
                            TaxGroupData();
                        } else {
                            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                        }

                    } else if (areaDelete.status >= 400 && areaDelete.status <= 405) {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
                    } else if (areaDelete.status >= 500 && areaDelete.status <= 505) {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                    } else {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
                    }
                }
                setLoading(false);
            });

        };

        return (
            <Dropdown isOpen={dropdownOpentax} toggle={toggleDropdowntax} >
                <DropdownToggle color="#00000" style={{ padding: '6px 12px', fontSize: '14px' }}>
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu container="body" style={{ zIndex: 999999 }}>
                    <DropdownItem style={toggleStyle} onClick={() => handlerateEditForm(row)}>Edit</DropdownItem>
                    <DropdownItem style={toggleStyleDelete} onClick={() => deleteTax()}>Delete</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    };



    const handleModal = (id) => {
        setmodal(!modal);
    };

    const redirectToAddForm = (data) => {
        const editFormURL = `${process.env.PUBLIC_URL}/settings/tax-add?id=${data.id}&title=${encodeURIComponent(data.title)}`;
        navigate(editFormURL);
    };

    const handleSwitchToggle = async (event, itemId) => {

        const checked = event.target.checked;
        let reqSwitch = null;
        if (checked === true) {
            reqSwitch = { tax_group_id: itemId }
        } else {
            reqSwitch = { tax_group_id: itemId }
        }
        const statusChange = await postClientUrlWithToken(authState.apiUrl, taxGroupStatus, reqSwitch);
        if (statusChange.status === 200) {
            if (statusChange.data.status === true) {
                toast.success(statusChange.data.message);
            } else {
                toast.error(statusChange.data.message);
            }
        } else if (statusChange.status >= 400 && statusChange.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (statusChange.status >= 500 && statusChange.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    }

    const TaxGroupData = async () => {
        setLoading(true)
        let data = await getClientUrlDataToken(authState.apiUrl, viewTaxGroup);
        if (data.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            setTaxGroupListData(data.data)
            if (Array.isArray(data.data)) {
                const filteredTax = data.data.filter((item) => {
                    const nameMatch = item.title && item.title.toLowerCase().includes(searchKeyword.toLowerCase());
                    return nameMatch;
                });
                setTaxGroupList(filteredTax.map((e, index) => {
                    return {
                        Sl: index + 1,
                        tax_group_id: e.id,
                        Name: e.title,
                        Tax:
                            <div>
                                <span>
                                    <Btn attrBtn={{ style: style2, className: 'me-2', color: 'primary btn-lg', type: 'button', onClick: () => { redirectToAddForm({ id: e.id, title: e.title }) } }}>Add Tax Rate</Btn>
                                </span>
                            </div>,
                        Description: e.description,
                        Total: e.percentage,
                        Type: e.order,
                        Status: <Media body className={`flex-grow-1 text-end icon-state`}>
                            <Label className="switch">
                                <Input type="checkbox"
                                    defaultChecked={e.status === "active" ? true : false}
                                    onChange={(event) => handleSwitchToggle(event, e.id)}
                                />
                                <span className="switch-state" ></span>
                            </Label>
                        </Media>,
                    }
                }))
            }
        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };

    const TaxData = async (id) => {
        setLoading(true);
        if (id) {
            const data = await getClientUrlDataToken(authState.apiUrl, viewTax + `?tax_group_id=${id}`);
            if (data && data.status === true) {
                setResetPaginationToggle(!resetPaginationToggle);
                // if (searchKeyword === '') {
                setTaxList(data.data.map((e, index) => {
                    return {
                        Si: index + 1,
                        tax_id: e.id,
                        Name: e.name,
                        tax_group_id: e.tax_group_id,
                        Sort_order: e.sort_order,
                        TaxPer: e.tax_percentage,
                        Description: e.description,
                    }
                }))
            } else if (data.status >= 400 && data.status <= 405) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
            } else if (data.status >= 500 && data.status <= 505) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
            }
            else {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
            }
            setLoading(false);
        }

    };
    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={TaxClass} parent="Settings" title={TaxClass} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-5px 0 -20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{TaxClassList}</H3>
                    <div>
                        <Link to={`${process.env.PUBLIC_URL}/settings/tax-group-add`} className="btn btn-primary">{Add}</Link>
                    </div>
                </CardHeader>
                <CardBody>
                    <span className='custom-span'>
                        <Form className="needs-validation" noValidate onSubmit={handleFilterSubmit}>
                            <Row className="ps-3 mb-4 custom-search-filter align-items-center">
                                <Col xs="6" sm="4" md="2" lg="2">
                                    <input className="form-control" placeholder={Search} type="text" value={searchKeyword} onChange={handleSearchInputChange}
                                    />
                                </Col>
                                <Col xs="6" sm="6" md="4" lg="3">
                                    <Btn className="custom-filter-btn" attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                                </Col>
                            </Row>
                        </Form>
                    </span>
                    <div className="order-history table-responsive">
                        <DataTable
                            data={taxGroupList}
                            columns={[
                                {
                                    name: <H4>Sl</H4>,
                                    selector: (row, index) => index + 1, // Assign SI dynamically based on current index
                                    width: '60px',
                                    sortable: false, // Disable sorting for SI
                                },
                                ...TaxGroupColumns // All other columns remain the same
                            ]}
                            pagination
                            style={{ border: '1px solid' }}
                        />
                    </div>
                </CardBody>
            </Card>
            <Popup isOpen={modal} title={TaxRate} toggler={handleModal} size="lg">
                <Card >
                    <CardBody>
                        <div className="order-history table-responsive">
                            <DataTable
                                data={taxList}
                                columns={[
                                    {
                                        name: <H4>Sl</H4>,
                                        selector: (row, index) => index + 1, // Assign SI dynamically based on current index
                                        width: '60px',
                                        sortable: false, // Disable sorting for SI
                                    },
                                    ...TaxColumns // All other columns remain the same
                                ]}
                                pagination
                                style={{ border: '1px solid' }}
                            />
                        </div>
                    </CardBody>
                </Card>
            </Popup>
        </Fragment>
    );
};

export default TaxRatesContainer;