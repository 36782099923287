import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row, Input } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import { Account, AccountAdd, AccountNo, BankAddress, BankNameError, BankNameText, BankPhone, DefaultActiveAccount, IFSC, Name, OpeningBalance, Save, UpiId, UpiMobile } from "../../../Constant";
import { addAccount, addAccountRole } from "../../../CommonUrl";
import { useSelector } from "react-redux";
import { postClientUrlWithToken } from "../../../Utils/restUtils";
import { toast } from 'react-toastify';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Typeahead from "../../../CommonElements/TypeAhead";

const schema = yup.object().shape({
    name: yup.string().required().label('Name'),
    balance: yup.string().required().label('Opening Balance'),
    accountNo: yup.string().required().label('Account Number'),
    upiId: yup
        .string()
        .test(
            'is-valid-upi',
            'UPI ID must be in the correct format (e.g., user@bank).',
            (value) => {
                if (!value) return true;
                return /^[a-zA-Z0-9.\-_]{2,}@[a-zA-Z]{2,}$/.test(value);
            }
        )
        .label('UPI ID'),
})
const AccountForm = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const authState = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const [defStatus, setDefStatus] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const inputElement = document.querySelectorAll('input[type="number"]');
        const preventScrollEvent = (event) => {
            event.preventDefault();
        };
        inputElement.forEach((inputElement) => {
            inputElement.addEventListener('wheel', preventScrollEvent);

            return () => {
                inputElement.removeEventListener('wheel', preventScrollEvent);
            };
        });
    }, []);

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = {
            name: data.name,
            opening_balance: data.balance,
            bank_name: data.bankName,
            bank_phone: data.bankPhone,
            bank_address: data.address,
            account_number: data.accountNo,
            ifsc_code: data.ifsc,
            upi_mobile: data.upiMobile,
            upi_id: data.upiId,
            default: defStatus===true ? "yes" : "no",
        }
        const addAccountResponse = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? addAccount : addAccountRole, postRequest);
        if (addAccountResponse.status === 200) {
            if (addAccountResponse.data.status === true) {
                toast.success(addAccountResponse.data.message)
                navigate(`${process.env.PUBLIC_URL}/banking/account`);
            } else {
                toast.error(addAreaResponse.data.message)
            }
        } else if (addAccountResponse.status >= 400 && addAccountResponse.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (addAccountResponse.status >= 500 && addAccountResponse.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (<SpinnerLoader />)}
            <Breadcrumbs mainTitle={Account} parent="Banking" title={Account} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{AccountAdd}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{Name} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="validationCustom01" type="text" {...register('name', { required: true })} />
                                <p className="text-danger">{errors.name?.message}</p>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{AccountNo} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="validationCustom02" type="number" min="0" {...register('accountNo', { required: true })} />
                                <p className="text-danger">{errors.accountNo?.message}</p>
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{OpeningBalance} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="validationCustom02" type="number" min="0" {...register('balance', { required: true })} />
                                <p className="text-danger">{errors.balance?.message}</p>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{BankNameText}</Label>
                                <input className="form-control" id="validationCustom01" type="text" {...register('bankName')} />
                                <span className="text-danger">{errors.bankName && BankNameError}</span>
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{BankPhone}</Label>
                                <input className="form-control" id="validationCustom02" type="number" min="0" {...register('bankPhone')} />
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{IFSC}</Label>
                                <input className="form-control" id="validationCustom02" type="text" min="0" {...register('ifsc')} />
                                <p className="text-danger">{errors.ifsc?.message}</p>
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{UpiMobile}</Label>
                                <input className="form-control" id="validationCustom02" type="number" min="0" {...register('upiMobile')} />
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{UpiId}</Label>
                                <input className="form-control" id="validationCustom02" type="text" min="0" {...register('upiId')} />
                                <p className="text-danger">{errors.upiId?.message}</p>
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md="6">
                                <Label className="form-label ">{BankAddress}</Label>
                                <textarea className="form-control" {...register('address')} maxLength={250} />
                            </Col>
                            <Col md="6">
                                <FormGroup className="m-t-15">
                                <div className="checkbox mb-0">
                                        <Input
                                            id="checkbox3"
                                            type="checkbox"
                                            checked={defStatus}
                                            onChange={(e) => setDefStatus(e.target.checked)}
                                        />
                                        <Label for="checkbox3">{DefaultActiveAccount}</Label>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default AccountForm;