import React, { Fragment } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { H5, H6 } from "../../../AbstractElements";
import CountUp from 'react-countup';
import HeaderCard from "../../Common/Component/HeaderCard";
import { PaymentCollection, Sale_Details } from "../../../Constant";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SaleDetails = (props) => {
    const authState = useSelector((state) => state.auth);
    const value = props.details;
    const formattedtoday = value.today.replace(/,/g, '');
    const formattedwallet = value.wallet_amount.replace(/,/g, '');
    const formattedmonth = value.this_month.replace(/,/g, '');
    const formatteddues = value.dues.replace(/,/g, '');

    const SalesData = [
        {
            id: 1,
            icon: 'icofont icofont-arrow-down font-primary',
            iconClass: 'bg-light-primary',
            title: <Link className="dashboardTableLinks" to={`${process.env.PUBLIC_URL}/report/payment-collection?day=today`}>
                {'Today'}
            </Link>,
            // title: 'Today',
            counter: formattedtoday,
            class: 'font-primary',
            // numbers: '-36%($258)'
        },
        {
            id: 2,
            icon: 'icofont icofont-arrow-down font-secondary',
            iconClass: 'bg-light-secondary',
            title: <Link className="dashboardTableLinks" to={`${process.env.PUBLIC_URL}/report/payment-collection?day=this_month`}>
            {' This Month'}
        </Link>,
            // title: 'Month',
            counter: formattedmonth,
            class: 'font-secondary',
            // numbers: '-15%($369)'
        },
        {
            id: 3,
            icon: 'icofont icofont-arrow-up font-success',
            iconClass: 'bg-light-success',
            title: <Link className="dashboardTableLinks" to={`${process.env.PUBLIC_URL}/report/unpaid-customer?day=dues`}>
                {'Total dues'}
            </Link>,
            // title: 'Total dues',
            counter: formatteddues,
            class: 'font-success',
            // numbers: '+65%($629)'
        },
        {
            id: 4,
            icon: 'icofont icofont-arrow-up font-success',
            iconClass: 'bg-light-success',
            title: <Link className="dashboardTableLinks" to={`${process.env.PUBLIC_URL}/customers/wallet`}>
                {'Wallet'}
            </Link>,
            // title: 'Wallet',
            counter: formattedwallet,
            class: 'font-success',
            // numbers: '+65%($629)'
        },
    ]

    return (
        <Fragment>
            <Col xl="6" className="box-col-6">
                <Card className="widget-joins widget-arrow">
                    <HeaderCard title={PaymentCollection} setting={true} />
                    <CardBody>
                        <Row className="gy-4">
                            {SalesData.map((data) => {
                                return (
                                    <Col sm="6" key={data.id}>
                                        <div className="widget-card">
                                            <div className="d-flex align-self-center">
                                                <div className={`widget-icon ${data.iconClass}`}>
                                                    <i className={`${data.icon}`}></i>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <H6>{data.title}</H6>
                                                    <H5>
                                                        <span className="counter">
                                                            {authState.currency} {data.counter}
                                                            {/* <CountUp end={data.counter} duration={0.8} decimals={2} className="m-0" /> */}
                                                        </span>
                                                        <span className={`${data.class}`}>{data.numbers}</span>
                                                    </H5>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};

export default SaleDetails;