import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, Btn, Breadcrumbs, H4 } from '../../../AbstractElements';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../Utils/restUtils';
import { Invoice, InvoiceList, Search, Filter, GenerateRecurring, Recurring, RecurringList } from '../../../Constant';
import { viewRecurringList, recurringApiConnection, recurringApiConnectionRole } from '../../../CommonUrl';
import { useSelector } from 'react-redux';
import { conditionalRow } from '../../Common/Rowstyle/Rowstyle';
import 'jspdf-autotable';
import { toast } from 'react-toastify';
import Transparentspinner from '../../Common/Loader/Transparentspinner';

const RecurringContainer = () => {
    const [spinnerTrans, setspinnerTrans] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const sortedBy = params.get("sortBy") || '';
    const sortedOrder = params.get("sortOrder") || '';
    const searchKey = params.get('search') || '';
    const authState = useSelector((state) => state.auth);
    const [invoiceLists, setInvoiceList] = useState([]);
    const [filters, setFilters] = useState({
        search: searchKey,
    });
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const isSearched = useRef(null);
    const [sortConfig, setSortConfig] = useState({ column: sortedBy, order: sortedOrder });

    useEffect(() => {
        fetchInvoice(1);
    }, []);

    const invoiceColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
        },
        {
            name: <H4>Subscriber Id</H4>,
            id: 'subscriber_id',
            selector: row =>
                (authState.userRole === 1 || authState.permissions.includes('show-customer-profile')) ?
                    <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${row.Customer_id}`}>
                        {row.Sub}
                    </Link> : row.Sub,
            sortable: true,
            wrap: true,
        },
        {
            name: <H4>Customer</H4>,
            id: 'customer_name',
            selector: row => row.Customer,
            sortable: true,
            wrap: true
        },
        {
            name: <H4>Package Name</H4>,
            id: 'package_name',
            selector: row => row.PackageName,
            sortable: true,
            wrap: true
        },
    ];

    // const handleSort = (column) => {
    //     let newOrder = 'asc';
    //     if (sortConfig.column === column.id) {
    //         newOrder = sortConfig.order === 'asc' ? 'desc' : 'asc';
    //     }
    //     setSortConfig({ column: column.id, order: newOrder });
    //     fetchInvoice(currentPage, perPage, filters.search, filters.start, filters.end, filters.customer, column.id, newOrder);
    // };

    const handleSort = (column) => {
        let newOrder = 'asc';
        if (sortedBy === column.id) {
            newOrder = sortedOrder === 'asc' ? 'desc' : 'asc';
            setSortConfig({ column: column.id, order: newOrder });
            navigate(`/billing/recurring?search=${filters.search}&sortBy=${column.id}&sortOrder=${newOrder}`);
        } else {
            newOrder = sortedOrder === 'asc' ? 'desc' : 'asc';
            setSortConfig({ column: column.id, order: newOrder });
            navigate(`/billing/recurring?search=${filters.search}&sortBy=${column.id}&sortOrder=${newOrder}`);
        }
    };

    const recurringApi = async () => {
        setLoading(true);
        const postRecurringApi = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? recurringApiConnection : recurringApiConnectionRole);
        if (postRecurringApi.status === 200) {
            if (postRecurringApi.data.status === true) {
                fetchInvoice(1, perPage, filters.search);
                toast.success(postRecurringApi.data.message);
            } else {
                toast.error(postRecurringApi.data.message);
            }
        } else if (postRecurringApi.status >= 400 && postRecurringApi.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (postRecurringApi.status >= 500 && postRecurringApi.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    }

    const handleApiError = (status) => {
        if (status >= 400 && status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (status >= 500 && status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const fetchInvoice = async (page, size = perPage) => {
        setspinnerTrans(true)
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 && viewRecurringList +
            `?page=${page}&page_length=${size}&search=${filters.search}&sort_by=${sortConfig.column ? sortConfig.column : ''}&sort_order=${sortConfig.order ? sortConfig.order : ''}`);
        if (response.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            const SerialNo = (page - 1) * size + 1;
            setInvoiceList(response.data.data.map((e, index) => {
                return {
                    Si: SerialNo + index,
                    Sub: <span>{e.subscriber_id}</span>,
                    Customer: e.customer_name,
                    Customer_id: e.customer_id,
                    PackageName: e.package_name,
                }
            }))
            setTotalRows(response.data.total);
        } else {
            handleApiError(response.status);
        }
        setspinnerTrans(false)
    };

    const handlePageChange = page => {
        fetchInvoice(page, perPage, filters.search, sortConfig.column, sortConfig.order);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchInvoice(page, newPerPage, filters.search, sortConfig.column, sortConfig.order);
        setPerPage(newPerPage);
    };

    const handleFilter = async (e) => {
        e.preventDefault();
        const search = isSearched.current.value;
        const queryParams = new URLSearchParams({
            search: search,
            sortBy: sortConfig.column || '',
            sortOrder: sortConfig.order || '',
        }).toString();
        navigate(`/billing/recurring?${queryParams}`);
        setFilters({
            search,
        });
        fetchInvoice(1, perPage, search);
    };


    return (
        <Fragment>
            {spinnerTrans && <Transparentspinner />}
            <Breadcrumbs mainTitle={Recurring} parent="Billing" title={Recurring} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -35px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{RecurringList}</H3>
                    <div>
                        <div className="btn btn-primary" style={{ marginTop: '15px', marginRight: '15px' }} onClick={() => recurringApi()}>{GenerateRecurring}</div>
                    </div>
                </CardHeader>
                <CardBody>
                    <span className=''>
                        <Form className="needs-validation" noValidate onSubmit={handleFilter}>

                            <Row className="ps-3 gap-sm-3 gap-md-2 mb-4 filterGap">
                                <Col sm="12" md='5' lg='2'>
                                    <input name='search' className="form-control" placeholder={Search} id="validationCustom01" type="text" ref={isSearched}
                                    />
                                </Col>
                                <Col sm="12" md="5" lg='1' className="custom-filtrs">
                                    <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                                </Col>
                            </Row>
                        </Form>
                    </span>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={invoiceColumns}
                            data={invoiceLists}
                            progressPending={loading}
                            onSort={handleSort} // Attach sorting handler here
                            sortServer // Enable server-side sorting
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            conditionalRowStyles={conditionalRow}
                            onChangePage={handlePageChange}
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment >
    );
};

export default RecurringContainer;