import React, { Fragment } from 'react';
import { H3, H2, P, Image, H4 } from '../../../../AbstractElements';
import { IssuedMay, PaymentDue, Email, Mob, TaxIn, Txn, SalesAgent } from '../../../../Constant';
import { Col, Media, Row } from 'reactstrap';

const InvoiceHeader = (props) => {
    const invoiceData = props.invoice;
    const company_address = invoiceData.company_address.replace(/"/g, '');
    const imageUrl = invoiceData.company_logo;
    return (
        <Fragment>
            <div className="invo-header">
                <Row>
                    <Col sm="4" className="custom-col">
                        <Media className='d-flex'>
                            <div className="d-flex-left">
                                <Image attrImage={{ className: 'd-flex-object img-60', src: imageUrl, alt: '' }} />
                            </div>
                            <div style={{width:'90%'}}>
                            <Media className="flex-grow-1 m-l-20">
                                <H2 attrH2={{ className: 'media-heading f-w-600' }}>{invoiceData.company_name}</H2>
                                <P>
                                    <span className="digits">{company_address}</span><br />
                                </P>
                            </Media>
                            </div>
                        </Media>
                        <P> {invoiceData.company_email}
                            <br />
                            <span className="digits"> + {invoiceData.company_country_code + " " + invoiceData.company_phone}</span>
                        </P>
                    </Col>


                    <Col sm="4" className="custom-col">
                        <div className="text-left">
                            <h5>
                                <span className="digits counter">Bill To: </span>
                            </h5>
                            <P>
                                <strong>{invoiceData.bill_to}</strong> <br />
                                {invoiceData.billing_address.replace(/"/g, '')}<br />
                                {Mob} : + {invoiceData.customer_phone_no}<br />
                                {Email} : {invoiceData.customer_email}<br />
                                {TaxIn} : {invoiceData.transaction_id}<br />
                            </P>
                        </div>
                    </Col>
                    <Col sm="4" className="custom-col">
                        <div className="text-md-end text-xs-center">
                            <H3>
                                <span className="digits counter">#{invoiceData.invoice_number}</span>
                            </H3>
                            <P>
                                <strong>{Txn} :{invoiceData.transaction_id} </strong> 
                                <br />
                                {IssuedMay}
                                <span className="digits">{invoiceData.invoice_dt}</span>
                                <br />
                                {PaymentDue}
                                <span className="digits">{invoiceData.payment_due_date}</span>
                                <br />
                                {SalesAgent}
                                <span className="digits">{invoiceData.sales_man}</span>
                            </P>
                        </div>
                    </Col>
                </Row>
            </div>
        </Fragment >
    );
};
export default InvoiceHeader;