import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardBody, Col, Form, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, Breadcrumbs, Btn, H4 } from '../../AbstractElements';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import DatePicker from 'react-datepicker';
import { getClientUrlDataToken } from '../../Utils/restUtils';
import { CustomerPayRep, CustomerPayment, Export, Search, Filter, Area, Account } from '../../Constant';
import { getArea, getAccount, getCustomerPaymentReport, getCustomerPaymentReportRole, getAreaRole, getAccountRole } from '../../CommonUrl';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Transparentspinner from '../Common/Loader/Transparentspinner';
import { useForm } from 'react-hook-form';


const CustomerPaymentReport = () => {
    const authState = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const [filters, setFilters] = useState({
        searchKey: params.get('search') || '',
        start_date: params.get('start_date') ? moment(params.get('start_date'), "DD-MM-YYYY").toDate() : '',
        end_date: params.get('end_date') ? moment(params.get('end_date'), "DD-MM-YYYY").toDate() : '',
        areaFilter: params.get('area') || '',
        accountId: params.get('account') || '',
    });
    const sortedBy = params.get("sortBy") || '';
    const sortedOrder = params.get("sortOrder") || '';
    const [customerLists, setCustomerList] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [dateRange, setDateRange] = useState([filters.start_date, filters.end_date]);
    const [startDate, endDate] = dateRange
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const [apiArea, setapiArea] = useState([]);
    const [apiAccount, setapiAccount] = useState([]);
    const formatStartDate = filters.start_date ? moment(filters.start_date).format("DD-MM-YYYY") : '';
    const formatEndDate = filters.end_date ? moment(filters.end_date).format("DD-MM-YYYY") : '';
    const [sortConfig, setSortConfig] = useState({ column: sortedBy, order: sortedOrder });
    const { register, handleSubmit, setValue } = useForm({
        defaultValues: { search: filters.searchKey, area: filters.areaFilter, account: filters.accountId },
    });

    const CustomerPaymentColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            center: false,
            width: '80px'
        },
        {
            name: <H4>Subscriber Id</H4>,
            id: 'subscriber_id',
            selector: row =>
                (authState.userRole === 1 || authState.permissions.includes('show-customer-profile')) ?
                    <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${row.Customer_id}`}>
                        {row.Sub}
                    </Link> : row.Sub,
            sortable: true,
            wrap: true,
            width: '125px',
        },
        {
            name: <H4>Name</H4>,
            id: 'customer_name',
            selector: row => row.Name,
            sortable: true,
            center: false,
            wrap: true
        },
        {
            name: <H4>Area</H4>,
            id: 'area_name',
            selector: row => row.Area,
            sortable: true,
            center: false,
            wrap: true
        },
        {
            name: <H4>Paid On</H4>,
            id: 'date',
            selector: row => row.Paidat,
            sortable: true,
            wrap: true
        },
        {
            name: <H4>Txn Id</H4>,
            id: 'transaction_id',
            selector: row => row.txnId,
            sortable: true,
            wrap: true
        },
        {
            name: <H4>Amount</H4>,
            id: 'amount',
            selector: row => authState.currency + " " + row.Amount,
            sortable: true,
            wrap: true,
            width: '100px'
        },
        {
            name: <H4>Reflected Invoice</H4>,
            id: 'invoice_no',
            selector: row =>
                <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile/invoice?invoice_id=${row.order_id}&customer_id=${row.Customer_id}`}>
                    {row.RefInvoice}
                </Link>,
            sortable: true,
            center: true,
            width: '145px'
        },
        {
            name: <H4>Payment Type</H4>,
            id: 'payment_type',
            selector: row => row.payType,
            sortable: true,
            center: true,
        },
    ];

    useEffect(() => {
        fetchCustomerPayment(1);
        areaData()
        accountData()
    }, []);

    useEffect(() => {
        setValue('search', filters.searchKey);
    }, [setValue, filters.search]);

    useEffect(() => {
        if (filters.areaFilter) {
            const selectedArea = apiArea.find((area) => area.id === parseInt(filters.areaFilter));
            if (selectedArea) {
                setValue('area', selectedArea.id.toString());
            }
        }
    }, [setValue, filters.areaFilter, apiArea]);

    useEffect(() => {
        if (filters.accountId) {
            const selectedAccount = apiAccount.find((account) => account.id === parseInt(filters.accountId));
            if (selectedAccount) {
                setValue('account', selectedAccount.id.toString());
            }
        }
    }, [setValue, filters.accountId, apiAccount]);

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleSort = (column) => {
        let newOrder = 'asc';
        if (sortedBy === column.id) {
            newOrder = sortedOrder === 'asc' ? 'desc' : 'asc';
            setSortConfig({ column: column.id, order: newOrder });
            navigate(`/report/customer-payment?search=${filters.searchKey}&start_date=${formatStartDate}&end_date=${formatEndDate}&area=${filters.areaFilter}&account=${filters.accountId}&sortBy=${column.id}&sortOrder=${newOrder}`);
        } else {
            newOrder = sortedOrder === 'asc' ? 'desc' : 'asc';
            setSortConfig({ column: column.id, order: newOrder });
            navigate(`/report/customer-payment?search=${filters.searchKey}&start_date=${formatStartDate}&end_date=${formatEndDate}&area=${filters.areaFilter}&account=${filters.accountId}&sortBy=${column.id}&sortOrder=${newOrder}`);
        }
    };

    const handleApiError = (status) => {
        if (status >= 400 && status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (status >= 500 && status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const onSubmit = async (data) => {
        const searchKey = data.search || '';
        const start_date = startDate ? moment(startDate).format("DD-MM-YYYY") : '';
        const end_date = endDate ? moment(endDate).format("DD-MM-YYYY") : '';
        const areaFilter = data.area || '';
        const accountId = data.account || '';
        const queryParams = new URLSearchParams({
            search: searchKey,
            start_date,
            end_date,
            area: areaFilter,
            account: accountId,
            sortBy: sortConfig.column || '',
            sortOrder: sortConfig.order || '',
        }).toString();
        navigate(`/report/customer-payment?${queryParams}`);
        setFilters({
            searchKey,
            start_date,
            end_date,
            areaFilter,
            accountId,
        });
        fetchCustomerPayment(currentPage, perPage);
    };

    const fetchCustomerPayment = async (page, size = perPage) => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCustomerPaymentReport +
            `?page=${page}&page_length=${size}&search=${filters.searchKey}&area_id=${filters.areaFilter}&start_date=${formatStartDate}&end_date=${formatEndDate}&account_id=${filters.accountId}&sort_by=${sortConfig.column ? sortConfig.column : ''}&sort_order=${sortConfig.order ? sortConfig.order : ''}` :
            getCustomerPaymentReportRole + `?page=${page}&page_length=${size}&search=${filters.searchKey}&area_id=${filters.areaFilter}&start_date=${formatStartDate}&end_date=${formatEndDate}&account_id=${filters.accountId}&sort_by=${sortConfig.column ? sortConfig.column : ''}&sort_order=${sortConfig.order ? sortConfig.order : ''}`);
        if (response.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            const valuePackages = response.payment.data;
            const SerialNo = (page - 1) * size + 1;
            setCustomerList(Object.keys(valuePackages).map((e, index) => {
                return {
                    Si: SerialNo + index,
                    Sub: valuePackages[e].subscriber_id,
                    Name: valuePackages[e].customer_name,
                    Paidat: valuePackages[e].date,
                    txnId: valuePackages[e].transaction_id,
                    Amount: valuePackages[e].amount,
                    RefInvoice: valuePackages[e].invoice_number,
                    payType: valuePackages[e].payment_type,
                    order_id: valuePackages[e].order_id,
                    Customer_id: valuePackages[e].customer_id,
                    Area: valuePackages[e].area_name,
                }
            }
            ))
            setTotalRows(response.payment.total);
        } else {
            handleApiError(data.status);
        }
        setLoading(false)
    };

    const exportCollectionExcel = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCustomerPaymentReport +
            `?export=payment_report&search=${filters?.searchKey ?? ''}&area_id=${filters?.areaFilter ?? ''}&start_date=${formatStartDate ?? ''}&end_date=${formatEndDate ?? ''}&account_id=${filters?.accountId ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}` :
            getCustomerPaymentReportRole + `?export=payment_report&search=${filters?.searchKey ?? ''}&area_id=${filters?.areaFilter ?? ''}&start_date=${formatStartDate ?? ''}&end_date=${formatEndDate ?? ''}&account_id=${filters?.accountId ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`);
        const dataToExport = response.payment.map(({ subscriber_id, customer_name, customer_address, area_name, transaction_id, date, amount, invoice_number, payment_type }, index) => ({ Sl: index + 1, Subscriber_id: subscriber_id, Name: customer_name, Address: customer_address, Area: area_name, Transaction_id: transaction_id, Date: date, Amount: amount, Invoice_number: invoice_number, Payment_type: payment_type }));
        if (response.status === true) {
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'payment Data');
            XLSX.writeFile(wb, 'payment_report.xlsx');
        } else {
            handleApiError(data.status);
        }
        setLoading(false);
    };

    const exportCollectionPdf = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCustomerPaymentReport +
            `?export=payment_report&search=${filters?.searchKey ?? ''}&area_id=${filters?.areaFilter ?? ''}&start_date=${formatStartDate ?? ''}&end_date=${formatEndDate ?? ''}&account_id=${filters?.accountId ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}` :
            getCustomerPaymentReportRole + `?export=payment_report&search=${filters?.searchKey ?? ''}&area_id=${filters?.areaFilter ?? ''}&start_date=${formatStartDate ?? ''}&end_date=${formatEndDate ?? ''}&account_id=${filters?.accountId ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`);
        const dataToExport = response.payment.map(({ subscriber_id, customer_name, customer_address, area_name, transaction_id, date, amount, invoice_number, payment_type }, index) => ({ Sl: index + 1, Subscriber_id: subscriber_id, Name: customer_name, Address: customer_address, Area: area_name, Transaction_id: transaction_id, Date: date, Amount: amount, Invoice_number: invoice_number, Payment_type: payment_type }));
        if (response.status === true) {
            const doc = new jsPDF();
            const columns = ["Sl", "Subscriber id", "Name", "Address", "Area", "Transaction id", "Date", "Amount", "invoice number", "Payment Type"];
            const rows = dataToExport.map(({ Subscriber_id, Name, Address, Area, Transaction_id, Date, Amount, Invoice_number, Payment_type }, index) => [index + 1, Subscriber_id, Name, Address, Area, Transaction_id, Date, Amount, Invoice_number, Payment_type]);
            doc.autoTable({
                head: [columns],
                body: rows
            });
            doc.save("collection_data.pdf");
        } else {
            handleApiError(data.status);
        }
        setLoading(false);
    };

    const handlePageChange = page => {
        fetchCustomerPayment(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchCustomerPayment(page, newPerPage);
        setPerPage(newPerPage);
    };

    const areaData = async () => {
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getArea : getAreaRole);
        if (data.status === true) {
            setapiArea(data.data);
        } else {
            handleApiError(data.status);
        }
    };

    const accountData = async () => {
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getAccount : getAccountRole);
        if (data.status === true) {
            setapiAccount(data.data);
        } else {
            handleApiError(data.status);
        }
    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={CustomerPayment} parent="Reports" title={CustomerPayment} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -25px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{CustomerPayRep}</H3>
                    <div>
                        {(authState.userRole === 1 || authState.permissions.includes('export-report')) &&
                            <CardBody className="dropdown-basic">
                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                    <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                        {Export} <i className="icofont icofont-arrow-up"></i>
                                    </DropdownToggle>
                                    <DropdownMenu container="body">
                                        <DropdownItem style={toggleStyle} onClick={() => exportCollectionExcel()} ><i className="fa fa-file-excel-o me-2"></i> Excel</DropdownItem>
                                        <DropdownItem style={toggleStyle} onClick={() => exportCollectionPdf()}><i className="fa fa-file-pdf-o me-2"></i> Pdf</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </CardBody>
                        }
                    </div>
                </CardHeader>

                <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Row className="ps-3 gap-sm-3 gap-md-2 filterGap">
                        <Col sm="12" md='5' lg='2'>
                            <input className="form-control" placeholder={Search} type="text"
                                {...register('search')} />
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element" >
                            <div style={{ position: 'relative', zIndex: 2 }}>
                                <DatePicker
                                    className="form-control digits w-100"
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    isClearable={true}
                                    monthsShown={2}
                                    popperPlacement="bottom"
                                    calendarClassName='parallel-calendar'
                                    placeholderText='Select Date Range'
                                />
                            </div>
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <select className="form-select" {...register('area')}>
                                <option value="">{Area}</option>
                                {apiArea.map((values, index) => (
                                    <option key={index} value={values.id}>{values.name}</option>
                                ))}
                            </select>
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <select className="form-select" {...register('account')}>
                                <option value="">{Account}</option>
                                {apiAccount.map((values, index) => (
                                    <option key={index} value={values.id}>{values.name}</option>
                                ))}
                            </select>
                        </Col>
                        <Col sm="12" md="5" lg='1' className="custom-filtrs">
                            <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                        </Col>
                    </Row>
                </Form>
                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={CustomerPaymentColumns}
                            data={customerLists}
                            onSort={handleSort} // Attach sorting handler here
                            sortServer // Enable server-side sorting
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default CustomerPaymentReport;