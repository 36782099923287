import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import SpinnerLoader from "../../Layout/SpinnerLoader";
import { AddComplaint, Complaint, CustomerTypeError, Phone, ProblemDetails, ProblemType, Save, ComplainId, SubscriberId, CustomerId, Status, Name, Address, Comments, StartDate, viewComplaint, Update } from "../../Constant";
import { getComplaint, getComplaintRole, updateComplaint, updateComplaintRole } from "../../CommonUrl";
import { useSelector } from "react-redux";
import { getClientUrlDataToken, postClientUrlWithToken } from "../../Utils/restUtils";
import { toast } from 'react-toastify';
import Typeahead from "../../CommonElements/TypeAhead";
import HoverableRowsClass from "../Tables/Reactstrap/BasicTable/HoverableRows";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Transparentspinner from "../Common/Loader/Transparentspinner";

const schema = yup.object().shape({
    status: yup.mixed().required().label('Status'),
    comments: yup.string().required().label('Comment '),
})


const ComplaintShow = () => {
    const authState = useSelector((state) => state.auth);
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");
    const [loading, setLoading] = useState(false);
    const [complainDetail, setComplainDetail] = useState([]);
    const navigate = useNavigate();
    const { register, handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const statusOptions = [
        { value: "open", label: "Open" },
        { value: "in_progress", label: "Inprogress" },
        { value: "closed", label: "Closed" }
    ]
    useEffect(() => {
        complainData();
    }, []);

    const complainData = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getComplaint + `?complaint_id=${param1}` : getComplaintRole +
            `?complaint_id=${param1}`);
        if (response.status === true) {
            setComplainDetail(response.data.data[0]);
        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);

    };

    const onSubmit = async (data) => {
        setLoading(true);

        const postRequest = {
            complaint_id: param1,
            comments: data.comments,
            status: data.status.value,
            customer_id: data.customer_id
        };
        const updateComplaints = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? updateComplaint : updateComplaintRole, postRequest);
        if (updateComplaints.status === 200) {
            if (updateComplaints.data.status === true) {
                toast.success(updateComplaints.data.message);
                navigate(`${process.env.PUBLIC_URL}/complaints`);
            } else if (updateComplaints.data.status === false) {
                toast.error(updateComplaints.data.message);
            } else {
                toast.error("Invalid response format");
            }
        } else if (updateComplaints.status >= 400 && updateComplaints.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (updateComplaints.status >= 500 && updateComplaints.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };

    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}
            <Breadcrumbs mainTitle={viewComplaint} parent="Billing" title={viewComplaint} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{viewComplaint}</H3>
                </CardHeader>
                <CardBody>
                    <div className="pro-group" style={{ borderBottom: '1px solid #5346861a', paddingBottom: '20px' }}>
                        <Row>
                            <Col md='4'>
                                <table className="table table-borderless">
                                    <tbody>
                                        <tr>
                                            <td><b>{ComplainId}</b></td>
                                            <td style={{ textAlign: 'center' }}><b>:</b></td>
                                            <td className="txt-primary">{complainDetail.complaint_no}</td>
                                        </tr>
                                        <tr>
                                            <td><b>{SubscriberId}</b></td>
                                            <td style={{ textAlign: 'center' }}><b>:</b></td>
                                            <td className="txt-primary">
                                                {(authState.userRole === 1 || authState.permissions.includes('show-customer-profile')) ?
                                                    <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${complainDetail.customer_id}`}>
                                                        {complainDetail.subscriber_id}
                                                    </Link> : complainDetail.subscriber_id
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><b>{CustomerId}</b></td>
                                            <td style={{ textAlign: 'center' }}><b>:</b></td>
                                            <td className="txt-primary">{complainDetail.customer_id}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                            <Col md='4'>
                                <table className="table table-borderless">
                                    <tbody>
                                        <tr>
                                            <td><b>{Name}</b></td>
                                            <td style={{ textAlign: 'center' }}><b>:</b></td>
                                            <td className="txt-primary">{complainDetail.customer_name}</td>
                                        </tr>
                                        <tr>
                                            <td><b>{Phone}</b></td>
                                            <td style={{ textAlign: 'center' }}><b>:</b></td>
                                            <td className="txt-primary">+ {complainDetail.phone}</td>
                                        </tr>
                                        <tr>
                                            <td><b>{Address}</b></td>
                                            <td style={{ textAlign: 'center' }}><b>:</b></td>
                                            <td className="txt-primary">{complainDetail.customer_address}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                            <Col md='4'>
                                <table className="table table-borderless">
                                    <tbody>
                                        <tr>
                                            <td><b>{StartDate}</b></td>
                                            <td style={{ textAlign: 'center' }}><b>:</b></td>
                                            <td className="txt-primary">{complainDetail.start_date}</td>
                                        </tr>
                                        <tr>
                                            <td><b>{Status}</b></td>
                                            <td style={{ textAlign: 'center' }}><b>:</b></td>
                                            <td className="txt-primary">
                                                <span className={`text-${complainDetail.status === 'open' ? 'success' : complainDetail.status === 'closed' ? 'danger' : complainDetail.status === 'in_progress' ? 'warning' : 'secondary'}`}>
                                                    {complainDetail.status}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><b>{ProblemType}</b></td>
                                            <td style={{ textAlign: 'center' }}><b>:</b></td>
                                            <td className="txt-primary">{complainDetail.problem_type}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                            <Col md='12' className="pt-3">
                                <table className="table table-borderless">
                                    <th><b>{ProblemDetails}</b></th>
                                    <tbody>
                                        <tr>
                                            <td className="txt-primary">{complainDetail.problem_detail}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </div>
                    {(authState.userRole === 1 || authState.permissions.includes('edit-complaint')) &&
                        <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                            <Row className="g-3 pt-3">
                                <Col md="6">
                                    <Label className="form-label">{Status} <span className="requireStar">*</span></Label>
                                    <Controller
                                        name="status"
                                        control={control}
                                        render={({ field }) => (
                                            <Typeahead
                                                options={statusOptions}
                                                onChange={(value) => {
                                                    field.onChange(value);
                                                }}
                                                value={field.value}
                                            />
                                        )}
                                    />
                                    <p className="text-danger">{errors.status?.message}</p>
                                </Col>
                                <Col md="6">
                                    <Label className="form-label">{Comments} <span className="requireStar">*</span></Label>
                                    <input className="form-control" type="text" {...register('comments')} />
                                    <p className="text-danger">{errors.comments?.message}</p>
                                </Col>
                            </Row>
                            <FormGroup>
                            </FormGroup>
                            <Btn attrBtn={{ color: "primary" }} type="submit">{Update}</Btn>
                        </Form>
                    }
                </CardBody>
            </Card>
            <Card >
                <CardBody>
                    {Object.keys(complainDetail).length > 0 && (
                        <HoverableRowsClass details={complainDetail} />
                    )}
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default ComplaintShow;